import React, { useEffect, useContext, useState } from "react";
import { MainContext } from "../../components/Context/context";
import "./gallery.scss";
import { useNavigate } from "react-router-dom";
import { GalleryImg } from "../../services/getAPIs";
import valueImg1 from "../../assets/images/Home/valueImg1.svg"
import collimg0 from '../../assets/images/products/collimg0.svg';
import collimg1 from '../../assets/images/products/collimg1.svg';
import collimg2 from '../../assets/images/products/collimg2.svg';
import collimg3 from '../../assets/images/products/collimg3.svg';
import closeIconRound from "../../assets/images/closeIconRound.svg"
import leftSlideIcon from "../../assets/images/Home/leftSlideIcon.svg"
import rightSlideIcon from "../../assets/images/Home/rightSlideIcon.svg"



const Gallery = () => {
    const { setnav, setGlobalSearch } = useContext(MainContext);
    const [galleryImgs, setGalleryImgs] = useState([])
    const [selectedImg, setSelectedImg] = useState("")
    const [openImg, setOpenImg] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0);


    useEffect(() => {
        setnav("Gallery");
        setGlobalSearch("")
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);

    useEffect(() => {
        GalleryImg().then((res) => {
            console.log(res.data.data.data)
            setGalleryImgs(res.data.data.data)
        }).catch((err) => {
            console.log(err)
        }
        )
    }, [])

    const openSelect = (item, i) => {
        console.log(currentIndex)
        setOpenImg(true)
        setCurrentIndex(i)
    }

    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? galleryImgs.length - 1 : prevIndex - 1
        );
    }

    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === galleryImgs.length - 1 ? 0 : prevIndex + 1
        );
    }
    return (
        <div>
            <div className="open-mail-quote" style={{ display: openImg ? "" : "none" }} onClick={() => setOpenImg(false)}></div>
            <div className="gallery-selected-img" style={{ display: openImg ? "" : "none" }} onClick={(e) => e.stopPropagation()}>
                <div className="round-icon-close">
                    <img src={closeIconRound} alt="close-icon" className="close-icon-gallery" onClick={() => setOpenImg(false)} />
                </div>
                <div className="testimonial-icons">
                    <img src={leftSlideIcon} alt="Testimonial" className="testimonials-move" onClick={handlePrev} />
                </div>
                <div>
                    <img src={galleryImgs[currentIndex]} className="selected-img-full" alt="full-img" />
                </div>
                <div className="testimonial-icons">
                    <img src={rightSlideIcon} alt="Testimonial" className="testimonials-move" onClick={handleNext} />
                </div>
            </div>
            <div className="wish-bg">
                <p className="wish-title">Our Gallery</p>
            </div>
            <div className="gallery-section">
                {galleryImgs.map((item, i) => {
                    return (
                        <>

                            <div className="gallery-frame" onClick={() => openSelect(item, i)}>
                                <img src={item} alt="img" className="gallery-img" />
                            </div>

                        </>
                    )
                })}
            </div>
        </div>
    )
};

export default Gallery;