import React, { useContext, useState, useEffect } from "react";
import { MainContext } from "../Context/context";
import { productData } from "../../data";
import ProductsSidebar from '../productsSidebar/ProductsSidebar';
import ProductPage from "../../pages/productpage/productpage";
import './Categories.scss';
import { useNavigate } from "react-router-dom";

const Categories = () => {
    const { setnav, categorySelected, setcategorySelected,
        collectionSelected, setcollectionSelected, step, setStep, urlCategory, setUrlCategory, removeSpecialCharacters, setGlobalSearch } = useContext(MainContext);

    const navigate = useNavigate();


    useEffect(() => {
        setGlobalSearch("")
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);




    return (
        <>
            <ProductPage />
            <div className="categories-container">
                <div className="psidebar">
                    <ProductsSidebar />
                </div>
                <div className="cat-box">
                    {
                        categorySelected.length !== 0 ?
                            <div>
                                <div className="cat-title">{categorySelected.productName}</div>
                                <div>
                                    {
                                        categorySelected.category_id === "prod0" || categorySelected.category_id === "prod1" ?
                                            <div className="all-products-box">
                                                {
                                                    categorySelected.collections.map((item, i) => {

                                                        return (
                                                            <div key={i} style={{ marginTop: "30px", width: "30%" }} onClick={() => {
                                                                navigate(`/products/${removeSpecialCharacters(item.category[0].name).split(" ").join("-")}`)
                                                                setStep([step[0], item.category[0].name])
                                                                setcategorySelected(item.category[0])
                                                                setGlobalSearch("")
                                                                window.scrollTo({
                                                                    top: 50,
                                                                    behavior: "smooth"
                                                                });
                                                            }}>
                                                                <div className="value-categories category-adj">
                                                                    <div>
                                                                        <p className="value-title">{item?.category[0]?.name}</p>
                                                                    </div>
                                                                    <img src={item.category[0].images[0]} alt="img" className="value-img" />
                                                                    <p className="value-btn" >View Category</p>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            : categorySelected.category_id !== "prod0" && categorySelected.category_id !== "prod1" ?
                                                <div className="all-products-box">
                                                    {categorySelected.collections.map((item, i) => (
                                                        <div key={i} style={{ marginTop: "30px", width: "30%" }}>
                                                            {
                                                                item[0].name !== null ?
                                                                    <div className="value-categories" onClick={() => {
                                                                        setStep([step[0], step[1], item[0].name])
                                                                        setcollectionSelected(item[0])
                                                                        navigate(`/products/${removeSpecialCharacters(step[1]).split(" ").join("-")}/${removeSpecialCharacters(item[0].name).split(" ").join("-")}`)
                                                                        window.scrollTo({
                                                                            top: 200,
                                                                            behavior: "smooth"
                                                                        })
                                                                    }}>
                                                                        <p className="value-title">{item[0].name}</p>
                                                                        {/* <p className="value-title">{item[0].name}</p> */}
                                                                        <img src={item[0].images[0]} alt="Value" className="value-img" />
                                                                        <p className="value-btn">View Collection</p>
                                                                    </div>
                                                                    :
                                                                    <div style={{ fontSize: "30px", margin: "20px 0px", fontWeight: "700" }}>No Records Available Under This Category ..!</div>
                                                            }
                                                        </div>
                                                    ))}

                                                </div>
                                                : ""
                                    }
                                </div>
                            </div>
                            : ""
                    }
                </div>
            </div>
        </>
    )
}

export default Categories