import React, { useContext, useState, useEffect } from "react";
import { MainContext } from "../Context/context";
import { productData } from "../../data";
import ProductPage from "../../pages/productpage/productpage";
import { collectionData } from "../../data";
import ProductsSidebar from '../productsSidebar/ProductsSidebar';
import './Collections.scss';
import { ToastContainer, toast } from 'react-toastify';
import { collection } from "../../services/getAPIs";
import { useNavigate } from "react-router-dom";
import plusIcon from "../../assets/images/Home/plusIcon.svg"
import minusIcon from "../../assets/images/Home/minusIcon.svg"

const Collections = () => {
    let navigate = useNavigate();
    const { setnav, categorySelected, setcategorySelected,
        collectionSelected, setcollectionSelected, step, setStep, itemSelected, setitemSelected, wishListData, setwishListData, collectionData, setCollectionData, setProductData, removeSpecialCharacters, setGlobalSearch } = useContext(MainContext);

    const [quantities, setQuantities] = useState({});


    // console.log("collectionSelected", JSON.stringify(collectionSelected))
    // console.log("categorySelected", JSON.stringify(categorySelected))
    // console.log("categorySelected", categorySelected)

    //remove selected collection from categorySelected

    useEffect(() => {
        setGlobalSearch("")
        collection(collectionSelected.collection_id).then((res) => {
            if (res.data.data.status) {
                setCollectionData(res.data.data.data)
                // console.log("res.data.data.data", JSON.stringify(res.data.data.data[0].collection[0]))
                // navigate("/products")
            } else {
                setCollectionData([])
            }
        })

    }, [collectionSelected, setCollectionData])

    // let DisplayCategory = categorySelected.data.filter((item) => {
    //     return item.collectionName !== collectionSelected.collectionName
    // })

    // let DisplayCategory = categorySelected.collections.filter((item) => {
    //     return item[0].collection_id !== collectionSelected.collection_id
    // })

    // console.log("DisplayCategory", JSON.stringify(DisplayCategory))

    const clickedItem = (item) => {
        // console.log("item", item)
        navigate(`/products/${removeSpecialCharacters(step[1]).split(" ").join("-")}/${removeSpecialCharacters(step[2]).split(" ").join("-")}/${removeSpecialCharacters(item.name).split(" ").join("-")}`)
        setitemSelected(item)
        if (step.length === 4) {
            setStep([step[0], step[1], step[2], item.name])
        } else {
            setStep([...step, item.name])
        }
    }

    const addToWishlist = (item) => {
        if (wishListData.find((wishListItem) => wishListItem.product_id === item.product_id)) {
            toast.success("Item added to wishlist");
            return
        } else {
            item.itemCount = 1
            // console.log("item-add", item)
            if (wishListData.includes(item)) {
                toast.success("Item added to wishlist");
                return
            }
            setwishListData([...wishListData, item])
            toast.success("Item added to wishlist");
        }
    }

    const addItem = (item) => {
        if (item.itemCount === 0) {
            let temp = [...collectionData[0].collection[0].products];
            let index = temp.indexOf(item);
            temp[index].itemCount = 1;
            setCollectionData([{ ...collectionData[0], collection: [{ ...collectionData[0].collection[0], products: temp }] }])
            setwishListData([...wishListData, item])
        } else {
            setwishListData(wishListData.map((obj) => obj.product_id === item.product_id ? { ...obj, itemCount: obj.itemCount + 1 } : obj))

            let temp = [...collectionData[0].collection[0].products];
            let index = temp.indexOf(item);
            temp[index].itemCount = temp[index].itemCount + 1;
            setCollectionData([{ ...collectionData[0], collection: [{ ...collectionData[0].collection[0], products: temp }] }])
        }
    }

    const removeItem = (item) => {
        if (item.itemCount === 0) {
            // toast.error("Item count cannot be less than 0")
            return
        }
        let temp = [...collectionData[0].collection[0].products];
        let index = temp.indexOf(item);
        temp[index].itemCount = temp[index].itemCount - 1;
        setCollectionData([{ ...collectionData[0], collection: [{ ...collectionData[0].collection[0], products: temp }] }])
        setwishListData(wishListData.map((obj) => obj.product_id === item.product_id ? { ...obj, itemCount: obj.itemCount - 1 } : obj))
        if (item.itemCount === 0) {
            setwishListData(wishListData.filter((obj) => obj.product_id !== item.product_id))
        }
    }

    const handleQuantityChange = (productId, quantity, item) => {
        console.log(productId, quantity, item.product_id)


        console.log(productId, "theID")

        //set the quantity of the item in the cart
        // setQuantities(prevQuantities => ({
        //   ...prevQuantities,
        //   [productId]: quantity
        // }));
        setQuantities({ [productId]: quantity })

        collectionData[0].collection[0].products.forEach((item) => {
            if (item.product_id === productId) {
                item.itemCount = quantity
            }
        })
        // console.log(seasonalItemsData, "seasonalItemsData")
        setCollectionData(collectionData)

        console.log(quantities[item.product_id])

        //add item to wishlist
        if (quantity > 0) {
            // if item is not in wishlist add it
            if (!wishListData.some((wishListItem) => wishListItem.product_id === productId)) {
                wishListData.push(item)
                setwishListData(wishListData)
            } else {
                // if item is in wishlist update the quantity
                wishListData.forEach((wishListItem) => {
                    if (wishListItem.product_id === productId) {
                        wishListItem.itemCount = quantity
                    }
                })
                setwishListData(wishListData)
            }
        } else if (quantity === 0 || isNaN(quantity)) {
            setwishListData(wishListData.filter((obj) => obj.product_id !== item.product_id))
        }


    };


    return (
        <>
            <ProductPage />
            <div className="categories-container">
                <div className="psidebar">
                    <ProductsSidebar />
                </div>
                <div className="cat-box">
                    {(collectionData.length > 0) ? (
                        <div className="selected-collection-box">
                            <p className="item-name">{collectionData[0].collection[0].name}</p>
                            <p className="item-tag">Items</p>
                            <div className="items-data-disp">
                                {collectionData[0].collection[0].products.map((item, i) => {
                                    console.log("item-coll", item)
                                    console.log(wishListData, "wishListDat-coll")
                                    if (wishListData.find((wishListItem) => wishListItem.product_id === item.product_id)) {
                                        item.itemCount = wishListData.find((wishListItem) => wishListItem.product_id === item.product_id).itemCount
                                    } else {
                                        item.itemCount = 0
                                    }
                                    return (
                                        <div key={i} className="value-items-data" onClick={() => clickedItem(item)}>
                                            <img src={item.images[0]} alt="Value" className="item-img" />
                                            <div className="item-details item-top-marg">
                                                <p className="new-title">{item.name}<span style={{ display: !item.unit_type ? "none" : "" }}>-</span>&nbsp;{item.unit_type ? item.unit_type : ""}</p>
                                                <p className="new-price">${item.price ? parseFloat(item.price).toFixed(2) : "--"}</p>
                                            </div>
                                            <div className="item-details-2">
                                                <p className="add-btn-text">Quantity</p>
                                                <div className="add-item-border">
                                                    <img src={minusIcon} alt="Minus" className="minus-icon" onClick={(e) => {
                                                        e.stopPropagation()
                                                        removeItem(item)
                                                    }} />
                                                    {/* <p className="item-number">{item.itemCount}</p> */}
                                                    <input type="number" min="0" className="item-number" value={item.itemCount}
                                                        onClick={(e) => e.stopPropagation()}
                                                        onChange={(e) =>
                                                            handleQuantityChange(item.product_id, parseInt(e.target.value), item)
                                                        }

                                                    />
                                                    <img src={plusIcon} alt="Plus" className="plus-icon" onClick={(e) => {
                                                        e.stopPropagation()
                                                        addItem(item)
                                                    }} />
                                                </div>
                                            </div>
                                            <p className="item-btn" onClick={(e) => {
                                                e.stopPropagation()
                                                addToWishlist(item)
                                            }}>Add to Wishlist</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    ) : (
                        ""
                    )}

                    {/* <div className="all-collections-box">
                    {
                        DisplayCategory.map((item, i) => (
                            <div className="category-collection">
                                {
                                    item[0].name !== null ?
                                        <div className="value-categories cate-length" key={i}>
                                            <p className="value-title">{item[0].name?.slice(0, 15)}<span style={{ display: item[0]?.name?.length > 14 ? "" : "none" }}>...</span></p>
                                            <img src={item[0].images[0]} alt="Value" className="value-img" />
                                            <p className="value-btn" onClick={() => {
                                                setStep([step[0], step[1], item[0].name])
                                                setcollectionSelected(item[0])
                                                window.scrollTo({
                                                    top: 200,
                                                    behavior: "smooth"
                                                })
                                            }}>View Collection</p>
                                        </div>
                                        :
                                        <div style={{ fontSize: "30px", margin: "20px 0px", fontWeight: "700" }}>No Records Available Under This Category ..!</div>
                                }
                            </div>
                        ))
                    }
                </div> */}
                </div>
                <ToastContainer style={{ top: '100px' }} />
            </div>
        </>
    )
}

export default Collections