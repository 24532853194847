import React, { useEffect, useContext, useState } from "react";
import { MainContext } from "../../components/Context/context";
import "./reviewpage.scss";
import { useNavigate } from "react-router-dom";
import ReviewIcon from "../../assets/images/reviewIcon.svg"
import ContactPage from "../contactpage/contactpage";
import Footer from "../../components/footer/footer";
import googleLogo from "../../assets/images/googleLogo.svg"
import { Reviews } from "../../services/getAPIs"

const Reviewpage = () => {

  const [reviews, setReviews] = useState([])


  let navigate = useNavigate();
  const { setnav, setGlobalSearch } = useContext(MainContext);
  useEffect(() => {
    setnav("Reviews");
    setGlobalSearch("")
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {

    Reviews().then((res) => {
      setReviews(res.data.data.data)
    }).catch((err) => {
      console.log(err)
    }
    )

  }, [])


  return (
    <div>
      <div className="wish-bg">
        <p className="wish-title">Reviews</p>
      </div>
      <div className="review-section">
        <div className="review-title">
          What our customers say
        </div>
        <div className="review-cards">
          {reviews.length === 0 && <div className="no-reviews">No reviews found</div>}
          {reviews.map((item) => {
            return (
              <div className="review-card1">
                <div className="review-image">
                  {/* <img src={item.images[0]} alt="icon" className="review-icon" /> */}
                  <p className="user-name-text">{item.name}</p>
                </div>
                <div className="review-desc">
                  {item.description}
                </div>
              </div>
            )
          })}
          
        </div>
        {/* <div className="google-review">
          <div className="google-review-title">
            Leave Us a Review
          </div>
          <div className="google-review-desc">
            We've found that customer reviews are very helpful in keeping our business thriving. We would truly appreciate a review from you.Visit your preferred site to leave a review or comment
          </div>
          <img src={googleLogo} alt="googleLogo" className="google-logo" />
        </div> */}
      </div>
    </div>
  );
};

export default Reviewpage