import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import AboutPage from '../src/pages/aboutpage/aboutpage';
import ContactPage from '../src/pages/contactpage/contactpage';
import HomePage from '../src/pages/homepage/homePage';
import ProductPage from './pages/productpage/productpage';
import Footer from './components/footer/footer';
import Navbar from './components/navbar/navbar';
import WishlistPage from './pages/wishlistpage/wishlistpage';
import Reviewpage from './pages/reviewpage/reviewpage';
import EachProduct from './pages/eachProductPage/eachProduct';
import Terms from './pages/terms/terms';
import Gallery from './pages/Gallery/gallery';
import Categories from './components/categories/Categories';
import Collections from './components/collections/Collections';


function MyRoutes() {
  return (
    <Router>
      <Navbar />
      <div style={{ marginTop: "20px" }}>
        <Routes>
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/products" element={<Navigate to="/products/All-Products" />} />
          <Route path="/products/:category" element={<Categories />} />
          <Route path="/products/:category/:collection" element={<Collections />} />
          <Route path="/products/:category/:collection/:product" element={<EachProduct />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/reviews" element={<Reviewpage />} />
          <Route path="/wishlist" element={<WishlistPage />} />
          <Route path="/eachproduct" element={<EachProduct />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/privacypolicy" element={<Terms />} />
          <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default MyRoutes;