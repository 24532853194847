import React, { useState, useEffect, useContext } from "react";
import { MainContext } from "../../components/Context/context";
import "./aboutpage.scss";
import aboutImg1 from "../../assets/images/aboutImg1.svg";
import aboutImg2 from "../../assets/images/aboutImg2.svg";

const AboutPage = () => {
  const { setnav,setGlobalSearch } = useContext(MainContext);
  useEffect(() => {
    setnav("About");
    setGlobalSearch("")
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <div className="wish-bg">
        <p className="wish-title">About Us</p>
      </div>
      <div className="about-section">
        <div className="about-top-section">
          <div className="about-top-section-left">
            A to Z Party Rentals Corporation is widely recognized as a pioneering force in the Toronto event rental industry. With a track record of being at the forefront of innovative designs and rental items, A to Z Party Rentals has earned a stellar reputation over the decades. The company's unwavering commitment to customer relationships sets it apart, with a level of service that is unmatched.
            <br />
            <br />
            As the go-to provider of rental items for every venue in the city, A to Z Party Rentals ensures that clients can rely on the timely delivery of expected items. Moreover, the experienced team at A to Z Party Rentals possesses unparalleled expertise in making sure that every event runs smoothly, leaving nothing to chance. With A to Z Party Rentals, you can be assured of top-notch service and know-how for an event that exceeds your expectations.
          </div>
          <div className="about-top-section-right">
            <img src={aboutImg1} alt="aboutImg1" className="about-img" />
          </div>
        </div>
        <br />
        <div className="hr"></div>
        <br />
        <div className="about-bottom-section">
          <div className="about-top-section-left">
            <img src={aboutImg2} alt="aboutImg1" className="about-img" />
          </div>
          <div className="about-top-section-right">
            Over the years, our business has flourished and grown to become the largest party rental firm in Canada, making events and celebrations in Toronto truly memorable.
            <br />
            <br />
            Our esteemed clientele includes Toronto's largest corporations, esteemed venues, social elite, and those who are the toast of the town when it comes to entertaining.
            <br />
            <br />
            It's a common sight to spot one of A to Z Party Rentals' distinctive red and white trucks delivering celebratory items to special events and occasions throughout the city. With the largest fleet in the area, our inventory speaks for itself.
            <br />
            <br />
            We invite you to book an appointment and visit our showroom to explore our extensive range of choices. Our experienced event professionals are ready to assist you with your next event, whether it's a small casual party at home, an office gathering, a corporate event, or an extravagant wedding like no other. Trust A to Z Party Rentals to make your event a resounding success.
          </div>
        </div>
        <br />
        <div className="hr"></div>
        <br />
        <div className="about-bottom">
        Choose A to Z Party Rentals for unparalleled excellence!
        </div>
        <div className="about-bottom-desc">
        We cordially welcome you to visit our expansive showroom and browse through our wide array of party rental options. Our experienced event professionals are ready to assist you in planning your next memorable party, be it a small casual gathering at home, an office party, a corporate event, or an extravagant celebration like no other. Come and discover the perfect party rentals for your unique event!
        </div>
      </div>
    </div>
  );
}

export default AboutPage;
