import React, { useState, useContext, useEffect } from "react";
import "./eachProduct.scss";
import SearchIcon from "../../assets/images/Home/searchIcon.svg";
import ItemImage1 from "../../assets/images/products/itemImage1.svg";
import ItemImage2 from "../../assets/images/products/itemImage2.svg";
import ItemImage3 from "../../assets/images/products/itemImage3.svg";
import ItemImage4 from "../../assets/images/products/itemImage4.svg";
import plusIcon from "../../assets/images/Home/plusIcon.svg"
import minusIcon from "../../assets/images/Home/minusIcon.svg"
import newImg1 from "../../assets/images/Home/newImg1.svg"
import newImg3 from "../../assets/images/Home/newImg3.svg"
import newImg4 from "../../assets/images/Home/newImg4.svg"
import cartIcon from "../../assets/images/Home/whiteWishlist.svg"
import greenTick from "../../assets/images/Home/redWishlist.svg"
import zoomIcon from "../../assets/images/Home/zoomIcon.svg"
import { MainContext } from "../../components/Context/context";
import { product, searchData } from "../../services/getAPIs";
import { ToastContainer, toast } from 'react-toastify';
import ProductPage from "../productpage/productpage";
import { useNavigate } from "react-router-dom"







const Images = [
  {
    id: 1,
    img: ItemImage1
  },
  {
    id: 2,
    img: ItemImage2
  },
  {
    id: 3,
    img: ItemImage3
  },
  {
    id: 4,
    img: ItemImage4
  }
]

const newItemsData = [
  {
    image: newImg1,
    title: "Propane Tank - 20 Lbs",
    price: "$28.00",
    description: "ropane tanks are typically steel vessels for storing the common LPG gases, propane or butane."
  },
  {
    image: newImg1,
    title: "Propane Tank - 40 Lbs",
    price: "$48.00",
    description: "ropane tanks are typically steel vessels for storing the common LPG gases, propane or butane."
  },
  {
    image: newImg3,
    title: "Charcoal",
    price: "$15.00",
    description: "Charcoal is a lightweight black carbon residue produced by strongly heating wood in minimal oxygen to remove all water and volatile constituents."
  },
  {
    image: newImg4,
    title: "Starter Fluid",
    price: "$5.00",
    description: "arting fluid is a volatile, flammable liquid which is used to aid the starting of internal combustion engines, especially during cold weather or in engines that are difficult to start using conventional starting procedures."
  },
  {
    image: newImg4,
    title: "Starter Fluid",
    price: "$5.00",
    description: "arting fluid is a volatile, flammable liquid which is used to aid the starting of internal combustion engines, especially during cold weather or in engines that are difficult to start using conventional starting procedures."
  }
]




const EachProduct = () => {
  const { step, setStep, collectionSelected, itemSelected, setitemSelected, productData, setProductData, wishListData, setwishListData, setcategorySelected, setcollectionSelected, openSearch, setOpenSearch, removeSpecialCharacters, setGlobalSearch } = useContext(MainContext)
  const [selectedImg, SetSelectedImg] = useState(itemSelected ? itemSelected?.images ? itemSelected.images[0] : "" : "");
  const [selectedProduct, setSelectesProduct] = useState("")
  const [quantities, setQuantities] = useState({})
  const navigate = useNavigate()

  console.log(itemSelected, "item-selected")

  console.log(step, collectionSelected, "Each")

  useEffect(() => {
    setGlobalSearch("")
    if (itemSelected?.images) {
      SetSelectedImg(itemSelected.images[0])
    }
    if (wishListData.find((wishListItem) => wishListItem.product_id === itemSelected.product_id)) {
      // fetch item count from wishlist data and set it to itemSelected
      itemSelected.itemCount = wishListData.find((wishListItem) => wishListItem.product_id === itemSelected.product_id).itemCount
    } else {
      itemSelected.itemCount = 0
      setitemSelected(itemSelected)
    }
  }, [itemSelected, setitemSelected, wishListData])

  useEffect(() => {
    console.log(itemSelected, "item-selected")
    product(itemSelected.product_id).then((res) => {
      if (res.data.data.status) {
        res.data.data.data[0].suggested_products.map((item) => {
          if (wishListData.some((wishListItem) => wishListItem.product_id === item.product_id)) {
            item.itemCount = wishListData.find((wishListItem) => wishListItem.product_id === item.product_id).itemCount
          } else {
            item.itemCount = 0
          }
        })
        // setSelectesProduct(res.data.data.data[0].product)
        setProductData(res.data.data.data[0].suggested_products)
        // console.log(res.data.data.data[0].product , collectionSelected, "product-selected")
      }
    }).catch((err) => {
      console.log(err)
    })
  }, [itemSelected, setProductData, wishListData, setwishListData])

  const addSelectItem = (item) => {
    if (item.itemCount === 0) {
      item.itemCount = 1
      setitemSelected(item)
      setwishListData([...wishListData, item])
    } else {
      setwishListData(wishListData.map((obj) => obj.product_id === item.product_id ? { ...obj, itemCount: obj.itemCount + 1 } : obj))
      item.itemCount = item.itemCount + 1
      setitemSelected(item)
    }
  }

  const removeSelectItem = (item) => {
    if (item.itemCount === 0) {
      // toast.error("Item count cannot be less than 0")
      return
    } else {
      setwishListData(wishListData.map((obj) => obj.product_id === item.product_id ? { ...obj, itemCount: obj.itemCount - 1 } : obj))
      item.itemCount = item.itemCount - 1
      setitemSelected(item)
    }
  }

  const addItem = (item) => {
    if (item.itemCount === 0) {
      let temp = [...productData];
      let index = temp.indexOf(item);
      temp[index].itemCount = 1;
      setProductData(temp);
      setwishListData([...wishListData, item])
    } else {
      setwishListData(wishListData.map((obj) => obj.product_id === item.product_id ? { ...obj, itemCount: obj.itemCount + 1 } : obj))

      let temp = [...productData];
      let index = temp.indexOf(item);
      temp[index].itemCount = temp[index].itemCount + 1;
      setProductData(temp);
    }
  }

  const removeItem = (item) => {
    if (item.itemCount === 0) {
      // toast.error("Item count cannot be less than 0")
      return
    }
    let temp = [...productData];
    let index = temp.indexOf(item);
    temp[index].itemCount = temp[index].itemCount - 1;
    setProductData(temp);
    setwishListData(wishListData.map((obj) => obj.product_id === item.product_id ? { ...obj, itemCount: obj.itemCount - 1 } : obj))
    if (item.itemCount === 0) {
      setwishListData(wishListData.filter((obj) => obj.product_id !== item.product_id))
    }
  }

  const addtoWishlist = (item) => {

    //add itemCount to item
    if (wishListData.find((wishListItem) => wishListItem.product_id === item.product_id)) {
      toast.success("Item added to wishlist");
      return
    } else {
      item.itemCount = 1;
      setwishListData([...wishListData, item])
      toast.success("Item added to wishlist");
    }
  }

  const openBox = async (item) => {

    let searchResults = await searchData();
    // console.log(item, "clicked")
    // console.log(JSON.stringify(searchResults.data.data.data), "searchResults")
    searchResults.data.data.data.forEach(element => {
      element.category[0].collections.forEach(element2 => {
        element2[0].products.forEach(element3 => {
          if (element3.product_id === item.product_id) {
            // console.log(element3, "element3")
            setitemSelected(element3);
            setcollectionSelected(element2[0]);
            setcategorySelected(element.category[0]);
            setStep(["Products", element.category[0].name, element2[0].name, element3.name]);
            navigate(`/products/${removeSpecialCharacters(element.category[0].name).split(" ").join("-")}/${removeSpecialCharacters(element2[0].name).split(" ").join("-")}/${removeSpecialCharacters(element3.name).split(" ").join("-")}`)
            setOpenSearch(false);
            setGlobalSearch("");
          }
        });
      });
    });
    window.scrollTo({
      top: 200,
      behavior: "smooth"
    });
  }

  const handleQuantityChange = (productId, quantity, item) => {
    console.log(productId, quantity, item.product_id)


    console.log(productId, "theID")

    //set the quantity of the item in the cart
    // setQuantities(prevQuantities => ({
    //   ...prevQuantities,
    //   [productId]: quantity
    // }));
    setQuantities({ [productId]: quantity })

    productData.forEach((item) => {
      if (item.product_id === productId) {
        item.itemCount = quantity
      }
    })
    // console.log(seasonalItemsData, "seasonalItemsData")
    setProductData(productData)

    console.log(quantities[item.product_id])

    //add item to wishlist
    if (quantity > 0) {
      // if item is not in wishlist add it
      if (!wishListData.some((wishListItem) => wishListItem.product_id === productId)) {
        wishListData.push(item)
        setwishListData(wishListData)
      } else {
        // if item is in wishlist update the quantity
        wishListData.forEach((wishListItem) => {
          if (wishListItem.product_id === productId) {
            wishListItem.itemCount = quantity
          }
        })
        setwishListData(wishListData)
      }
    } else if (quantity === 0 || isNaN(quantity)) {
      setwishListData(wishListData.filter((obj) => obj.product_id !== item.product_id))
    }


  };



  return (
    <>
      <ProductPage />
      <div className="eachProduct_container">
        <div className="top-banner">
          <div className="top-banner-left">
            <div className="eachProduct-banner">
              {itemSelected?.images?.map((item) => {
                return (
                  <div className={"each-img " + (selectedImg === item ? "active" : "")}>
                    <img src={item} alt="item" className="item-image" onClick={() => SetSelectedImg(item)} />
                  </div>
                )
              })}
            </div>
            <div className="selected-image">
              <img src={selectedImg} alt="selected" className="selected-img" />
              <div className="selectImg-btns">
                <button className="selectImg-btn" onClick={() => addtoWishlist(itemSelected)}>Add to Wishlist </button>
                <div className="select-add-item-border">
                  <img src={minusIcon} alt="Minus" className="select-minus-icon" onClick={() => removeSelectItem(itemSelected)} />
                  {/* <p className="select-item-number">{itemSelected.itemCount}</p> */}
                  <input type="number" min="0" className="select-item-number" value={itemSelected.itemCount}
                    onChange={(e) => handleQuantityChange(itemSelected.product_id, parseInt(e.target.value), itemSelected)}

                  />
                  <img src={plusIcon} alt="Plus" className="select-plus-icon" onClick={() => addSelectItem(itemSelected)} />
                </div>
              </div>
            </div>
          </div>
          <div className="top-banner-right">
            <div className="product-details">
              <p className="product-title">{itemSelected.name}<span style={{ display: !itemSelected.unit_type ? "none" : "" }}>-</span>&nbsp;{itemSelected.unit_type ? itemSelected.unit_type : ""}</p>
              <p className="product-price">Price : <span className="product-amount">${parseFloat(itemSelected.price).toFixed(2)}</span></p>
              {/* <p className="product-trend">Trending</p> */}
              <p className="desc">Product Description :</p>
              <p className="product-detail">{itemSelected.description}</p>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div className="value-banner-title">
          <p className="value-banner-text"> <span className="value-highlight">Suggested Items</span> For You!</p>
          <div className="hr1"></div>
        </div>
        <div className="value-favs bottom-tile">
          <div className="value-banner-block">

            {productData.map((item, index) => {
              if (isNaN(item.itemCount)) {
                item.itemCount = 0
              }
              return <div className="new-categories" key={index} onClick={() => openBox(item)}>
                <div className="newbox-imgs">
                  <img src={wishListData.find((wishListItem) => wishListItem.product_id === item.product_id) && item.itemCount !== 0 ? greenTick : cartIcon} alt="Cart" className="cart-icon" onClick={(e) => {
                    addtoWishlist(item)
                    e.stopPropagation()
                  }} />
                  <img src={item.images[0]} alt="New" className="new-img" />
                  {/* <img src={zoomIcon} alt="Zoom" className="zoom-icon" /> */}
                </div>
                <div className="item-details">
                  <p className="new-title">{item.name}<span style={{ display: !item.unit_type ? "none" : "" }}>-</span>&nbsp;{item.unit_type ? item.unit_type : ""}</p>
                  <p className="new-price">${item.price ? parseFloat(item.price).toFixed(2) : "--"}</p>
                </div>
                <div className="item-details-2">
                  <p className="add-btn-text">Quantity</p>
                  <div className="add-item-border">
                    <img src={minusIcon} alt="Minus" className="minus-icon" onClick={(e) => {
                      removeItem(item)
                      e.stopPropagation()
                    }} />
                    {/* <p className="item-number">{item.itemCount}</p> */}
                    <input type="number" min="0" className="item-number" value={item.itemCount}
                      onChange={(e) => handleQuantityChange(item.product_id, parseInt(e.target.value), item, e.stopPropagation())}
                    />
                    <img src={plusIcon} alt="Plus" className="plus-icon" onClick={(e) => {
                      addItem(item)
                      e.stopPropagation()
                    }} />
                  </div>
                </div>
                <p class="home-item-btn" onClick={(e) => {
                  addtoWishlist(item)
                  e.stopPropagation()
                }} >Add to Wishlist</p>
                {/* <p className="desc-title">Product Description :</p>
              <p className="desc-text">{item.description}</p> */}
              </div>
            })}
          </div>
        </div>
        <ToastContainer style={{ top: '100px' }} />
      </div>
    </>
  );
}

export default EachProduct;