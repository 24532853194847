import React from "react";
import './App.css';
import MyRoutes from "./routes";
import a2zmainlogo from './assets/images/a2zmainlogo.svg';

function App() {
  return (
    <div className="App">
      <div className="page-layout">
        <MyRoutes />
      </div>
      {/* <div className="mob-layout">
        <div style={{margin: "30px 15px"}}>We Are Coming soon to Mobile ..!</div>
        <div style={{margin: "30px 15px"}}>Please Check Our Website in PC / Laptop.</div>
        <div style={{margin: "30px"}}>
          <img src={a2zmainlogo} alt="" />
        </div>
      </div> */}
    </div>
  );
}

export default App;
