import React, { useEffect, useContext, useState } from "react";
import { MainContext } from "../../components/Context/context";
import "./wishlistpage.scss";
import { useNavigate } from "react-router-dom";
import deleteIco from "../../assets/images/deleteIco.svg";
import wishItem from "../../assets/images/wishItem.svg";
import plusIcon from "../../assets/images/Home/plusIcon.svg"
import minusIcon from "../../assets/images/Home/minusIcon.svg"
import closeIconRound from "../../assets/images/closeIconRound.svg"
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import { Contact } from "../../services/getAPIs";
import { toast, ToastContainer } from "react-toastify";
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // Import the autotable plugin
import axios from "axios";
import wishConfirm from "../../assets/images/wishConfirm.svg"
import a2zmainlogo from '../../assets/images/a2zmainlogo.svg';
import a2zmainpng from '../../assets/images/atozmainlogo.png';
import a2zmain from '../../assets/images/atozcompress.png'




const WishlistPage = () => {

  const [openMail, setOpenMail] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [isChecked, setIsChecked] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("")
  const [company, setCompany] = useState("")
  const [eventTime, setEventTime] = useState("")
  const [promoCode, setPromoCode] = useState("")
  const [message, setMessage] = useState("")
  const [timeSelect, setTimeSelect] = useState("")
  const [clearData, setClearData] = useState(false)
  const [selectProduct, setSelectProduct] = useState("")
  const [quantities, setQuantities] = useState({})







  let navigate = useNavigate();
  const { setnav, wishListData, setwishListData, theItemsData, setTheItemsData, seasonalItemsData, setSeasonalItemsData, } = useContext(MainContext);
  useEffect(() => {
    setnav("Wishlist");
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  let WishHeaders = ["Product", "Unit Price", "Quantity", "Total"];

  const DeleteItem = (item) => {
    setwishListData(wishListData.filter((i) => i !== item));
    //set the item count to 0
    // let newItemsData = seasonalItemsData.map((i) => {
    //   if (i.id === item.id) {
    //     i.itemCount = 0
    //   }
    //   return i
    // })
    // setSeasonalItemsData(newItemsData)
  }

  useEffect(() => {
    //if item count is 0 then remove item from wishlist
    // console.log(wishListData, "wishListData")
    let newWishList = wishListData.filter((item) => {
      return item.itemCount !== 0
    }
    )
    setwishListData(newWishList)
  }, [])

  const addWishData = (item) => {
    let temp = [...wishListData]
    let index = temp.indexOf(item)
    temp[index].itemCount = temp[index].itemCount + 1
    setwishListData(temp)

    //set the item count to 0
    // let newItemsData = seasonalItemsData.map((i) => {
    //   if (i.id === item.id) {
    //     i.itemCount = i.itemCount + 1
    //   }
    //   return i
    // }
    // )
    // setSeasonalItemsData(newItemsData)
  }

  const removeWishData = (item) => {
    if (item.itemCount === 1) {
      setSelectProduct(item)
      setClearData(true)
      return
    }
    let temp = [...wishListData]
    let index = temp.indexOf(item)
    temp[index].itemCount = temp[index].itemCount - 1
    setwishListData(temp)

    if (temp[index].itemCount === 0) {
      setOpenAlert(true)
    }
  }

  const handleQuantityChange = (productId, quantity, item) => {
    console.log(productId, quantity, item.product_id)

    console.log(isNaN(quantity), "quantity")

    // if (isNaN(quantity)) {
    //   wishListData.forEach((wishListItem) => {
    //     if (wishListItem.product_id === productId) {
    //       console.log("adefshg","quantity")
    //       wishListItem.itemCount = 1
    //     }
    //   })
    //   setwishListData(wishListData)
    //   return
    // }


    console.log(productId, "theID")

    //set the quantity of the item in the cart
    // setQuantities(prevQuantities => ({
    //   ...prevQuantities,
    //   [productId]: quantity
    // }));
    setQuantities({ [productId]: quantity })

    // seasonalItemsData.forEach((item) => {
    //   if (item.product_id === productId) {
    //     item.itemCount = quantity
    //   }
    // })
    // console.log(seasonalItemsData, "seasonalItemsData")
    // setSeasonalItemsData(seasonalItemsData)

    console.log(quantities[item.product_id])

    //add item to wishlist
    if (quantity > 0) {
      // if item is not in wishlist add it
      if (!wishListData.some((wishListItem) => wishListItem.product_id === productId)) {
        wishListData.push(item)
        setwishListData(wishListData)
      } else {
        // if item is in wishlist update the quantity
        wishListData.forEach((wishListItem) => {
          if (wishListItem.product_id === productId) {
            wishListItem.itemCount = quantity
          }
        })
        setwishListData(wishListData)
      }
    } else if (quantity === 0) {
      setwishListData(wishListData.filter((obj) => obj.product_id !== item.product_id))
    } else if (isNaN(quantity)) {
      wishListData.forEach((wishListItem) => {
        if (wishListItem.product_id === productId) {
          console.log("adefshg", "quantity")
          wishListItem.itemCount = 1
        }
      })
      setwishListData(wishListData)
    }


  };

  const handleFormSubmit = async (e) => {
    const nameRegex = /^[A-Za-z\s]+$/;
    const phoneRegex = /^[0-9]+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!nameRegex.test(name)) {
      toast.error("Please enter valid name")
      return
    }
    if (!phoneRegex.test(phone)) {
      toast.error("Please enter valid phone number")
      return
    }
    if (phone.length !== 10) {
      toast.error("Phone number should be of 10 digits")
      return
    }
    if (!emailRegex.test(email)) {
      toast.error("Please enter valid email")
      return
    }
    if (!name) {
      toast.error("Please enter your name")
      return
    }
    if (!phone) {
      toast.error("Please enter your phone number")
      return
    }
    if (!email) {
      toast.error("Please enter your email")
      return
    }
    if (!eventTime) {
      toast.error("Please enter your event time")
      return
    }
    // if(!message){
    //   toast.error("Please tell us the purpose of event!!")
    // }
    e.preventDefault();


    // console.log(wishListData, "wishListData")

    const doc = new jsPDF();
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(18);
    doc.setTextColor(162, 72, 61); // Set text color to blue
    doc.addImage(a2zmain, 'PNG', 80, 10, 45, 20);
    doc.text('Lead Details', 20, 50);
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(12);
    doc.setTextColor(162, 72, 61); // Set text color to black
    doc.text('Name: ', 20, 70);
    doc.text('Phone: ', 20, 80);
    doc.text('Email: ', 20, 90);
    doc.text('Company: ', 20, 100);
    doc.text('Best Time to Call: ', 20, 110);
    doc.text('Event Time: ', 20, 120);
    doc.text('Promo Code: ', 20, 130);
    doc.text('Subscribed to Newsletter: ', 20, 140);
    doc.text('Message: ', 20, 150);

    doc.setTextColor(0, 0, 0); // Set text color to black
    doc.text(`${name}`, 90, 70);
    doc.text(`${phone}`, 90, 80);
    doc.text(`${email}`, 90, 90);
    doc.text(`${company}`, 90, 100);
    doc.text(`${timeSelect}`, 90, 110);
    doc.text(`${eventTime}`, 90, 120);
    doc.text(`${promoCode}`, 90, 130);
    doc.text(`${isChecked}`, 90, 140);
    doc.text(`${message}`, 90, 150);

    const tableColumns = ['ID', 'PRODUCT NAME', 'PRICE', 'QUANTITY'];
    const tableRows = wishListData.map(item => [item.product_id, item.name, `$${item.price}`, `${item.itemCount}`]);

    doc.autoTable({
      head: [tableColumns],
      body: tableRows,
      headStyles: {
        fillColor: [162, 72, 61] // Set background color of table header
      },
      styles: {
        cellPadding: 5, // Set cell padding
        valign: 'middle', // Center cell contents vertically
        halign: 'center' // Center cell contents horizontally
      },
      startY: 180,
    });

    const pdfData = doc.output('blob');
    const formData = new FormData();
    formData.append("file", pdfData, "wishlist.pdf");

    let data = {
      request_Type: "wishlist",
      name,
      phone,
      company_name: company,
      email,
      message,
      subscription: isChecked,
      event_time: eventTime,
      best_time_to_call: timeSelect,
      promo_code: promoCode,
      file: formData.get('file')
    }
    // console.log(data, "data")

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    axios.post("https://atozbackend.atozpartyrentals.ca/contactus/device/api/v1/contactUs", data, config)
      .then((res) => {
        if (res.data.status) {
          toast.success("Your request has been sent successfully")
          setOpenMail(false)
          setName("")
          setPhone("")
          setCompany("")
          setEmail("")
          setMessage("")
          setEventTime("")
          setPromoCode("")
          setIsChecked(false)
        } else {
          toast.error("Something went wrong")
        }
      })
      .catch((err) => {
        console.log(err, "err")
      })
  }

  const handleDownloadPDF = () => {
    if (wishListData.length === 0) {
      toast.error("Please add items to wishlist")
      return
    }
    const doc = new jsPDF();

    doc.setFont('helvetica', 'bold');
    doc.setFontSize(18);
    doc.setTextColor(162, 72, 61); // Set text color to blue
    doc.text('WishList Items', 15, 50);
    doc.addImage(a2zmainpng, 'PNG', 80, 10, 45, 20); // Adjust coordinates and dimensions


    const tableColumns = ['ID', 'PRODUCT NAME', 'PRICE', 'QUANTITY'];
    const tableRows = wishListData.map(item => [item.product_id, item.name, `$${item.price}`, `${item.itemCount}`]);

    doc.autoTable({
      head: [tableColumns],
      body: tableRows,
      headStyles: {
        fillColor: [162, 72, 61] // Set background color of table header
      },
      styles: {
        cellPadding: 5, // Set cell padding
        valign: 'middle', // Center cell contents vertically
        halign: 'center' // Center cell contents horizontally
      },
      startY: 55,
    });

    doc.save('wishlist.pdf');

  }

  const handleTerms = () => {
    window.open('/privacypolicy', '_blank');
  }

  let today = new Date().toISOString().split("T")[0];

  const confirmDeleteBox = (item) => {
    setOpenAlert(false)
    setwishListData([])
  }

  const confirmDeleteData = (item) => {
    console.log(item, "item")
    setClearData(false)
    DeleteItem(item)
  }



  return (
    <div>
      <div className="wish-bg">
        <p className="wish-title" >Get Your Quote</p>
      </div>
      <div className="mail-box" style={{ display: openMail ? "" : "none" }}>
        <img src={closeIconRound} alt="close" className="close-icon-box" onClick={() => setOpenMail(false)} />
        <div className="information-form">
          <div className="information-title">Request For Information</div>
          <div className="information-desc">One of our team member will get in touch with you to discuss how we can help make your event the best it can be.</div>
          <div className="wish-contact-title">Contact Information</div>
          <div style={{ color: "#a2483d", fontWeight: 700 }}>* Fields are mandatory</div>
          {/* <div style = {{color:"#a2483d",fontWeight:700}}>* Fields are mandatory</div> */}
          <div className="wish-contact-form">
            <input type="text" placeholder="Name*" className="wish-input" value={name} onChange={(e) => setName(e.target.value)} />
            <input type="number" placeholder="Phone Number*" className="wish-input left-marg-wish" value={phone}
              onChange={(e) => setPhone(e.target.value)} />
          </div>
          <div className="wish-contact-form">
            <input type="text" placeholder="Email*" className="wish-input" value={email} onChange={(e) => setEmail(e.target.value)} />
            <input type="text" placeholder="Company Name (optional)" className="wish-input left-marg-wish" value={company} onChange={(e) => setCompany(e.target.value)} />
          </div>
          <div className="wish-contact-form">
            <div className="wish-input space-align">
              <span className="text-timing">Event Date*</span>&nbsp;&nbsp;&nbsp;&nbsp;<input type="date" min={today} placeholder="Event Date*" value={eventTime} onChange={(e) => setEventTime(e.target.value)} />
            </div>
            <div className="wish-input left-marg-wish space-align"><span className="text-timing">Best Time to call (optional)</span>&nbsp;&nbsp;&nbsp;&nbsp;<input type="time" id="appt" name="appt"
              min="09:00" max="21:00" value={timeSelect} onChange={(e) => setTimeSelect(e.target.value)} />&nbsp;
            </div>
          </div>
          <div className="wish-contact-form">
            <input type="text" placeholder="Promo code(optional)" className="wish-input" value={promoCode} onChange={(e) => setPromoCode(e.target.value)} />
          </div>
          <div className="wish-contact-form">
            <textarea placeholder="Message (optional)" rows="8" cols="85" className="wish-textarea" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
          </div>
          <br />
          <div className="wish-contact-form subscribe-btn">
            <label className="switch">
              <input type="checkbox" checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
              <span className="slider round"></span>
            </label>
            <p className="subs-newsbtn">&nbsp;Subscribe to our newsletter to get updates on latest offers and seasonal items recommendations</p>
          </div>
          <br />
          <p className="subs-newsbtn">On, subscribing you are accepting to our privacy policy</p>
          <p className="subs-newsbtn clickpolicybtn" onClick={handleTerms}>Click to read our privacy policy</p>
          <div className="wish-form-submit" onClick={handleFormSubmit}>
            <p className="wish-form-title">Submit</p>
          </div>
        </div>
      </div>
      <div className="mail-box" style={{ display: openAlert ? "" : "none", width: "90%", maxWidth: "400px" }}>
        <div className="confirm-box">
          <div>
            <img src={wishConfirm} alt="confirm" className="confirm-img" />
          </div>
          <p className="confirm-text">Are you sure you want to clear the list?</p>
          <div className="confirm-btns">
            <p className="confirm-btn" onClick={confirmDeleteBox}>Yes</p>
            <p className="confirm-btn1" onClick={() => setOpenAlert(false)}>No</p>
          </div>
        </div>
      </div>
      <div className="mail-box" style={{ display: clearData ? "" : "none", width: "90%", maxWidth: "400px" }}>
        <div className="confirm-box">
          <div>
            <img src={wishConfirm} alt="confirm" className="confirm-img" />
          </div>
          <p className="confirm-text">Are you sure you want to remove the product?</p>
          <div className="confirm-btns">
            <p className="confirm-btn" onClick={() => confirmDeleteData(selectProduct)}>Yes</p>
            <p className="confirm-btn1" onClick={() => setClearData(false)}>No</p>
          </div>
        </div>
      </div>
      <div className="wish-tags">
        <p className="wish-tag" onClick={handleDownloadPDF}>Print</p>
        <p className="wish-tag" onClick={() => wishListData.length > 0 ?
          setOpenAlert(true)
          : ""}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Clear List</p>
      </div>
      <div>
        <p className="wish-main-title">My Wishlist</p>
      </div>
      <div>
        <div className="wish-header" style={{ display: wishListData.length > 0 ? "" : "none" }}>
          {WishHeaders.map((item, index) => {
            return (
              <p key={index} className="wish-header-text">{item}</p>
            );
          })}
        </div>
        <hr className="wish-hr" />
      </div>
      <div className="open-mail-quote" style={{ display: openMail ? "" : "none" }}></div>
      <div className="open-mail-quote" style={{ display: openAlert ? "" : "none" }}></div>
      <div className="open-mail-quote" style={{ display: clearData ? "" : "none" }}></div>

      {wishListData.length > 0 ? (
        window.innerWidth > 800 ? (
          <div>
            {wishListData.map((item, index) => {
              console.log(isNaN(item.itemCount))
              // if (isNaN(item.itemCount)) {
              //   item.itemCount = 1;
              // }
              return <>
                <div className="wish-item-data" key={index}>
                  <div className="wish-item">
                    <img src={deleteIco} alt="delete" className="delete-icon" onClick={() => {
                      setSelectProduct(item)
                      setClearData(true)
                    }} />
                    <img src={item.images[0]} alt="valueImg1" className="wish-img" />
                    <p className="wish-text">{item.name}</p>
                  </div>
                  <div className="wish-item">
                    <p className="wish-grey-price">${parseFloat(item.price).toFixed(2)}</p>
                  </div>
                  <div className="wish-item">
                    <div className="add-item-wish-border">
                      <img src={minusIcon} alt="Minus" className="minus-wish-icon" onClick={() => removeWishData(item)} />
                      {/* <p className="item-wish-number">{item.itemCount}</p> */}
                      <input type="number" min="0" className="item-wish-number" value={item.itemCount}
                        onChange={(e) => handleQuantityChange(item.product_id, parseInt(e.target.value), item)}

                      />
                      <img src={plusIcon} alt="Plus" className="plus-wish-icon" onClick={() => addWishData(item)} />
                    </div>
                  </div>
                  <div className="wish-item">
                    <p className="wish-price">${parseFloat(item.price * item.itemCount).toFixed(2)}</p>
                  </div>
                </div>
                <hr className="wish-hr" />
              </>
            })}
            <div className="quote-btn" onClick={() => setOpenMail(true)}>
              Get your quote
            </div>
            <ToastContainer style={{ top: '100px' }} />
          </div>
        ) : (
          <div>
            {wishListData.map((item, index) => {
              return (
                <>
                  <div className="mp-wish-list" key={index}>
                    <div className="mp-wish-item">
                      <div className="mp-wish-img-left">
                        <img src={item.images[0]} alt="valueImg1" className="mp-wish-img" />
                        <div className="mp-wish-item-border">
                          <img src={minusIcon} alt="Minus" className="minus-wish-icon" onClick={() => removeWishData(item)} />
                          <p className="item-wish-number">{item.itemCount}</p>
                          <img src={plusIcon} alt="Plus" className="plus-wish-icon" onClick={() => addWishData(item)} />
                        </div>
                      </div>
                      <div>
                        <p className="mp-wish-text">{item.name}</p>
                        <p className="mp-wish-grey-price">Price:<span className="mp-wish-price">${item.price}</span></p>
                        <p className="mp-wish-total-price">Total:<span className="mp-wish-amount">${item.price * item.itemCount}.00</span></p>
                      </div>
                    </div>
                    <div className="mp-wish-delete">
                      <img src={deleteIco} alt="delete" className="mp-wish-delete-icon" onClick={() => {
                        setSelectProduct(item)
                        setClearData(true)
                      }} />
                    </div>
                  </div>
                  <div className="mp-hr1"></div>
                </>
              )
            })}
            <div className="quote-btn" onClick={() => setOpenMail(true)}>
              Get your quote
            </div>
            <ToastContainer style={{ top: '100px' }} />
          </div>
        )
      ) : (
        <>
          <div className="wish-empty">
            <p>Wishlist is empty....</p>
          </div>
          <ToastContainer style={{ top: '100px' }} />
        </>
      )}
      <hr className="wish-hr" style={{ display: wishListData.length > 0 ? "none" : "" }} />
      {/* <div className="wish-button">
        <p className="wish-btn-text">Get Your Quote</p>
      </div> */}

      {/* <div className="information-form">
        <div className="information-title">Request For Information</div>
        <div className="information-desc">One of our team member will get in touch with you to discuss how we can help make your event the best it can be.</div>
        <div className="wish-contact-title">Contact Information</div>
        <div className="wish-contact-form">
          <input type="text" placeholder="Name*" className="wish-input" />
          <input type="number" placeholder="Phone Number*" className="wish-input left-marg-wish" />
        </div>
        <div className="wish-contact-form">
          <input type="text" placeholder="Email*" className="wish-input" />
          <input type="number" placeholder="Company Name (optional)" className="wish-input left-marg-wish" />
        </div>
        <div className="wish-contact-form">
          <textarea placeholder="Message*" rows="8" cols="85" className="wish-textarea" ></textarea>
        </div>
        <div className="wish-form-submit">
          <p className="wish-form-title">Submit</p>
        </div>
      </div> */}
    </div>
  );
};

export default WishlistPage;
