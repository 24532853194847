//category Images
import catdimg from './assets/images/products/catdimg.svg';
import catimg0 from './assets/images/products/catimg0.svg';
import catimg1 from './assets/images/products/catimg1.svg';
import catimg2 from './assets/images/products/catimg2.svg';
import catimg3 from './assets/images/products/catimg3.svg';
import catimg4 from './assets/images/products/catimg4.svg';


//collection Images
import collimg0 from './assets/images/products/collimg0.svg';
import collimg1 from './assets/images/products/collimg1.svg';
import collimg2 from './assets/images/products/collimg2.svg';
import collimg3 from './assets/images/products/collimg3.svg';


//item Images
import item11 from './assets/images/products/item11.svg';
import item12 from './assets/images/products/item12.svg';
import item13 from './assets/images/products/item13.svg';
import item14 from './assets/images/products/item14.svg';
import item21 from './assets/images/products/item21.svg';
import item31 from './assets/images/products/item31.svg';
import item41 from './assets/images/products/item41.svg';
import item51 from './assets/images/products/item51.svg';
import item61 from './assets/images/products/item61.svg';
import item71 from './assets/images/products/item71.svg';
import item81 from './assets/images/products/item81.svg';





export const NewItemsData = [
    {
        id:"item0",
        itemName:`Charcoal Barbeque
        2' x 5'`,
        itemPrice:125.00,
        itemCount:0,
        itemDesc:"A charcoal grill is a type of home grill that uses lump charcoal or charcoal briquettes as a heating source to cook food. The charcoal fuel is typically located under the grill grates, with wood chips and lighter fluid starting up the hot coals.",
        itemImg:[item11, item12, item13, item14],
    },
    {
        id:"item1",
        itemName:`Propane Barbeque
        2' x 5'`,
        itemPrice:200.00,
        itemCount:0,
        itemDesc:"Barbecuing with propane is cleaner and more predictable than barbecuing with charcoal, and it's an easy-to-learn process.",
        itemImg:[item21],
    },
    {
        id:"item2",
        itemName:`Barbeque Utensil Set`,
        itemPrice:10.00,
        itemCount:0,
        itemDesc:"Barbecue grill tool set is well made of sturdy stainless steel which makes the set extremely durable to last for a long time.",
        itemImg:[item31],
    },
    {
        id:"item3",
        itemName:`Griddle - 2' x 2'`,
        itemPrice:50.00,
        itemCount:0,
        itemDesc:"The griddle is quite like a hotplate, a flat, smooth metal surface with the heat source directly underneath.",
        itemImg:[item41],
    }
]



export const itemData = [
    {
        id:"item0",
        itemName:`Charcoal Barbeque
        2' x 5'`,
        itemPrice:125.00,
        itemCount:0,
        itemDesc:"A charcoal grill is a type of home grill that uses lump charcoal or charcoal briquettes as a heating source to cook food. The charcoal fuel is typically located under the grill grates, with wood chips and lighter fluid starting up the hot coals.",
        itemImg:[item11, item12, item13, item14],
    },
    {
        id:"item1",
        itemName:`Propane Barbeque
        2' x 5'`,
        itemPrice:200.00,
        itemCount:0,
        itemDesc:"Barbecuing with propane is cleaner and more predictable than barbecuing with charcoal, and it's an easy-to-learn process.",
        itemImg:[item21],
    },
    {
        id:"item2",
        itemName:`Barbeque Utensil Set`,
        itemPrice:10.00,
        itemCount:0,
        itemDesc:"Barbecue grill tool set is well made of sturdy stainless steel which makes the set extremely durable to last for a long time.",
        itemImg:[item31],
    },
    {
        id:"item3",
        itemName:`Griddle - 2' x 2'`,
        itemPrice:50.00,
        itemCount:0,
        itemDesc:"The griddle is quite like a hotplate, a flat, smooth metal surface with the heat source directly underneath.",
        itemImg:[item41],
    },
    {
        id:"item4",
        itemName:`Propane Tank - 
        20 Lbs.`,
        itemPrice:28.00,
        itemCount:0,
        itemDesc:"Ropane tanks are typically steel vessels for storing the common LPG gases, propane or butane.",
        itemImg:[item51],
    },
    {
        id:"item5",
        itemName:`Propane Tank - 
        40 Lbs.`,
        itemPrice:48.00,
        itemCount:0,
        itemDesc:"Ropane tanks are typically steel vessels for storing the common LPG gases, propane or butane.",
        itemImg:[item61],
    },{
        id:"item6",
        itemName:`Charcoal`,
        itemPrice:15.00,
        itemCount:0,
        itemDesc:"Charcoal is a lightweight black carbon residue produced by strongly heating wood in minimal oxygen to remove all water and volatile constituents.",
        itemImg:[item71],
    },
    {
        id:"item7",
        itemName:`Starter Fluid`,
        itemPrice:5.00,
        itemCount:0,
        itemDesc:"Arting fluid is a volatile, flammable liquid which is used to aid the starting of internal combustion engines, especially during cold weather or in engines that are difficult to start using conventional starting procedures.",
        itemImg:[item81],
    },
]

export const collectionData = [
    {
        id: "coll0",
        collectionName:"BARBEQUES",
        collectionImage:collimg0,
        items: [itemData[0], itemData[3], itemData[6]]
    },
    {
        id: "coll1",
        collectionName:"CHAFFING DISHES",
        collectionImage:collimg1,
        items: [itemData[1], itemData[2], itemData[2]]
    },
    {
        id: "coll2",
        collectionName:"COOKING EQUIPMENT",
        collectionImage:collimg2,
        items: [itemData[0], itemData[1], itemData[2], itemData[3], itemData[4]]
    },
    {
        id: "coll3",
        collectionName:"ELECTRIC FOOD WARMERS",
        collectionImage:collimg3,
        items: [itemData[2], itemData[4], itemData[6], itemData[7]]
    },


]

export const categoryData = [
    {
        id:"prod2",
        productName:"Bar Equipment",
        productImage:catimg0,
        data:[]
    },
    {
        id:"prod3",
        productName:"Barbeques & Food Warmers",
        productImage:catimg1,
        data:[collectionData[0],  collectionData[1], collectionData[2],  collectionData[3]]
    },
    {
        id:"prod4",
        productName:"Beverage Equipment",
        productImage:catimg2,
        data:[]
    },
    {
        id:"prod5",
        productName:"Chairs",
        productImage:catimg3,
        data:[]
    },
    {
        id:"prod6",
        productName:"Concession Equipment",
        productImage:catdimg,
        data:[]
    },
    {
        id:"prod7",
        productName:"Crockery",
        productImage:catdimg,
        data:[]
    },
    {
        id:"prod8",
        productName:"Decor Items",
        productImage:catimg4,
        data:[]
    },
    {
        id:"prod9",
        productName:"Food Service Equipment",
        productImage:catdimg,
        data:[]
    },
    {
        id:"prod10",
        productName:"Games",
        productImage:catdimg,
        data:[]
    },
    {
        id:"prod11",
        productName:"Glassware",
        productImage:catdimg,
        data:[]
    },
    {
        id:"prod12",
        productName:"Linen",
        productImage:catdimg,
        data:[]
    },
    {
        id:"prod13",
        productName:`Serving Bowls, Stands & Trays`,
        productImage:catdimg,
        data:[]
    },
    {
        id:"prod14",
        productName:`Serving Utensils`,
        productImage:catdimg,
        data:[]
    },
    {
        id:"prod15",
        productName:`Show Service Equipment`,
        productImage:catdimg,
        data:[]
    },
    {
        id:"prod16",
        productName:`Staging & Dace Floors`,
        productImage:catdimg,
        data:[]
    },
    {
        id:"prod17",
        productName:`Table Accessories`,
        productImage:catdimg,
        data:[]
    },
    {
        id:"prod18",
        productName:`Tables`,
        productImage:catdimg,
        data:[]
    },
    {
        id:"prod19",
        productName:`Tents & Canopies`,
        productImage:catdimg,
        data:[]
    },
]

export const productData = [
    {
        id:"prod0",
        productName:"All Products",
        productImage:"",
        data:[...categoryData]
    },
    {
        id:"prod1",
        productName:"New Products",
        productImage:"",
        data:[categoryData[0], categoryData[1], categoryData[2], categoryData[3]]
    },
    ...categoryData
]

