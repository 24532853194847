import React, { createContext, useState, useEffect } from "react";
import { productData } from "../../data";
import { NewItemsData } from "../../data";
import { sidebarData, searchData, collection } from "../../services/getAPIs";
import { useNavigate } from "react-router-dom";

export const MainContext = createContext();

const MainContextProvider = ({ children }) => {


    const [pagemask, setpagemask] = useState("");
    const [nav, setnav] = useState("home");
    const [categorySelected, setcategorySelected] = useState([]);
    const [collectionSelected, setcollectionSelected] = useState({});
    const [itemSelected, setitemSelected] = useState({});
    const [step, setStep] = useState(["Products", "All Products"]);
    const [wishListData, setwishListData] = useState(localStorage.getItem("wishListData") ? JSON.parse(localStorage.getItem("wishListData")) : []);
    const [theItemsData, setTheItemsData] = useState(NewItemsData);
    const [sidebarItems, setSidebarItems] = useState([]);
    const [collectionData, setCollectionData] = useState([]);
    const [seasonalItemsData, setSeasonalItemsData] = useState([]);
    const [productData, setProductData] = useState([]);
    const [globalSearch, setGlobalSearch] = useState("");
    const [searchBox, setSearchBox] = useState(false);
    const [allProducts, setAllProducts] = useState([]);
    const [openSearch, setOpenSearch] = useState(false);
    // const [urlCategory, setUrlCategory] = useState("")



    useEffect(() => {
        localStorage.setItem("wishListData", JSON.stringify(wishListData));
    }, [wishListData, setwishListData]);

    useEffect(() => {
        const url = window.location.href;
        const mainUrl = window.location.origin; // Main URL
        let data = url.substring(mainUrl.length); // Data after the main URL
        console.log(data.split("/"), "url");
        // setUrlCategory(data.split("/")[1])
    }, []);

    const removeSpecialCharacters = (str) => {
        // Regular expression to match special characters
        var regex = /[!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?~-]/g;
        
        // Replace special characters with an empty string
        var result = str.replace(regex, '');
        
        return result;
      }


    useEffect(() => {
        const url = window.location.href;
        const mainUrl = window.location.origin; // Main URL
        const data = url.substring(mainUrl.length); // Data after the main URL
        // Process the data as needed
        console.log(data.split("/"), "url");
        // setUrlCategory(data.split("/")[1])
        sidebarData().then((res) => {
            // console.log(res.data.data, "sidebar-data")
            if (res.data.data.status) {
                let newdata = res.data.data.data.slice(0, 4)
                let fulldata = [{
                    category: [
                        {
                            category_id: "prod0",
                            name: "All Products",
                            image: "",
                            collections: [...res.data.data.data]
                        }
                    ]
                },
                {
                    category: [
                        {
                            category_id: "prod1",
                            name: "New Products",
                            image: "",
                            collections: [...newdata]
                        }
                    ]
                },
                ...res.data.data.data];
                console.log(fulldata, "fulldata")
                setSidebarItems(fulldata);
                if (data.split("/")[2]) {
                    let urlCategory = data.split("/")[2]
                    fulldata.forEach(element => {
                        element.category.forEach(element2 => {
                            if (removeSpecialCharacters(element2.name).split(" ").join("-") === urlCategory) {
                                // console.log(element2, "element2")
                                setcategorySelected(element2);
                                setStep(["Products", element2.name]);
                                if (data.split("/")[3]) {
                                    // console.log(element2.collections, "abcd")
                                    element2.collections.forEach(element3 => {
                                        // console.log(element3[0], "element3")
                                        let collectionCategory = data.split("/")[3];
                                        console.log(collectionCategory, "collectionCategory")
                                        if (removeSpecialCharacters(element3[0].name).split(" ").join("-") === collectionCategory) {
                                            setcollectionSelected(element3[0]);
                                            setStep(["Products", element2.name, element3[0].name]);
                                            if (data.split("/")[4]) {
                                                collection(element3[0].collection_id).then((res) => {
                                                    console.log(JSON.stringify(res.data.data.data[0].collection[0].products), "collection-data")
                                                    setCollectionData(res.data.data.data);
                                                    res.data.data.data[0].collection[0].products.forEach(element4 => {
                                                        console.log("abcd")
                                                        if (removeSpecialCharacters(element4.name).split(" ").join("-") === data.split("/")[4]) {
                                                            setitemSelected(element4);
                                                            setStep(["Products", element2.name, element3[0].name, element4.name]);
                                                        }
                                                    });
                                                })
                                            }
                                        }
                                    }
                                    );
                                }
                            }
                        });
                    });
                } else {
                    setcategorySelected(fulldata[0].category[0]);
                }
            } else {
                setSidebarItems([]);
                setcategorySelected([]);
            }

        });
    }, []);

    useEffect(() => {
        searchData().then((res) => {
            // console.log(JSON.stringify(res.data.data.data), "search-data")
            //push all categories in one array
            let theProducts = [];
            res.data.data.data.forEach(element => {
                element.category[0].collections.forEach(element2 => {
                    element2[0].products.forEach(element3 => {
                        theProducts.push(element3);
                    });
                });
            });

            //remove duplicates
            theProducts = theProducts.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.product_id === thing.product_id
                ))
            )
            // console.log(JSON.stringify(theProducts), "allProducts")
            setAllProducts(theProducts);




            // res.data.data.data.forEach(element => {


            // });

        })
    }, []);






    const value = {
        pagemask,
        setpagemask,
        nav,
        setnav,
        categorySelected, setcategorySelected,
        collectionSelected, setcollectionSelected,
        collectionData, setCollectionData,
        itemSelected, setitemSelected,
        step, setStep,
        wishListData, setwishListData,
        theItemsData, setTheItemsData,
        productData, setProductData,
        globalSearch, setGlobalSearch,
        seasonalItemsData, setSeasonalItemsData,
        sidebarItems, setSidebarItems,
        allProducts, setAllProducts,
        searchBox, setSearchBox,
        openSearch, setOpenSearch,
        removeSpecialCharacters
        // urlCategory, setUrlCategory
    }
    return (
        <MainContext.Provider value={value}>
            {children}
        </MainContext.Provider>
    );
}

export default MainContextProvider;

export const MainContextConsumer = MainContext.Consumer