import axios from "axios";
import { API_URL } from "./api";

export const homeBanner = () => {
  return axios.get(
    `${API_URL}/device/api/v1/banner`
  );
};

export const valuePackages = () => {
  return axios.get(
    `${API_URL}/device/api/v1/valuePackage`
  );
}

export const sidebarData = () => {
  return axios.get(
    `${API_URL}/device/api/v1/sidebar`
  );
}

export const collection = (collectionID) => {
  return axios.get(
    `${API_URL}/device/api/v1/collection/${collectionID}`
  );
}

export const product = (productID) => {
  return axios.get(
    `${API_URL}/device/api/v1/product/${productID}`
  );
}

export const seasonalItems = () => {
  return axios.get(
    `${API_URL}/device/api/v1/seasonalitems`
  );
}

export const searchData = () => {
  return axios.get(
    `${API_URL}/device/api/v1/search`
  );
}

export const Contact = (body) => {
  return axios.post(
    `${API_URL}/contactus/device/api/v1/contactUs`,
    { ...body }
  );
}

export const Reviews = () => {
  return axios.get(
    `${API_URL}/device/api/v1/reviews`
  );
}

export const GalleryImg = () => {
  return axios.get(
    `${API_URL}/device/api/v1/gallery`
  );
}