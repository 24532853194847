import React, { useState, useEffect, useContext } from "react";
import { MainContext } from "../../components/Context/context";
import "./productpage.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SearchIcon from "../../assets/images/Home/searchIcon.svg";
import Categories from "../../components/categories/Categories";
import { productData } from "../../data";
import EachProduct from "../eachProductPage/eachProduct";
import Collections from "../../components/collections/Collections";
import { collection, searchData } from "../../services/getAPIs";
import { useNavigate } from "react-router-dom";


const ProductPage = () => {
  const { setnav, step, setStep, setcategorySelected, categorySelected, setcollectionSelected, setitemSelected, sidebarItems, globalSearch, setGlobalSearch, allProducts, setSidebarItems, openSearch, setOpenSearch, collectionSelected, removeSpecialCharacters
  } = useContext(MainContext);

  const navigate = useNavigate();

  useEffect(() => {
    setnav("Products");
    setGlobalSearch("")
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  // useEffect(() => {
  //   if (!collectionSelected) {
  //     setStep(["Products", "All Products"]);
  //     setcategorySelected(sidebarItems[0]?.category[0])
  //   }
  //   console.log("collectionSelected", collectionSelected)
  //   console.log("categorySelected", categorySelected)
  //   console.log("sidebarItems", sidebarItems)
  //   console.log("step", step)
  // }, [sidebarItems, setcategorySelected, setStep, collectionSelected])

  const openBox = async (item) => {
    let searchResults = await searchData();
    // console.log(item, "clicked")
    // console.log(JSON.stringify(searchResults.data.data.data), "searchResults")
    searchResults.data.data.data.forEach(element => {
      element.category[0].collections.forEach(element2 => {
        element2[0].products.forEach(element3 => {
          if (element3.product_id === item.product_id) {
            // console.log(element3, "element3")
            setitemSelected(element3);
            setcollectionSelected(element2[0]);
            setcategorySelected(element.category[0]);
            setStep(["Products", element.category[0].name, element2[0].name, element3.name]);
            navigate(`/products/${removeSpecialCharacters(element.category[0].name).split(" ").join("-")}/${removeSpecialCharacters(element2[0].name).split(" ").join("-")}/${removeSpecialCharacters(element3.name).split(" ").join("-")}`)
            setOpenSearch(false);
            setGlobalSearch("");
          }
        });
      });
    });
    window.scrollTo({
      top: 200,
      behavior: "smooth"
    });
  }

  return (
    <div className="product_container">
      <div className="home-search-disp">
        <div className="home-search" style={{ display: openSearch ? "" : "none" }}>
          <div className="banner-search-bar">
            <img src={SearchIcon} alt="Search Icon" className="search-icon" style={{ top: "23px" }} />
            <input type="search" alt="search" placeholder="Search Item . . . ." className="search-banner" value={globalSearch} onChange={(e) => setGlobalSearch(e.target.value)} style={{ paddingLeft: "30px" }} />
            <p className="search-textbtn">Search</p>
          </div>
          <div style={{ display: globalSearch.length > 0 ? "" : "none" }}>
            {allProducts.filter((item) => item.name.toLowerCase().includes(globalSearch.toLowerCase())).map((item, index) => {
              return <div key={index} className="selected-home-prod" onClick={() => openBox(item)}>
                {item.name}
              </div>
            })}
          </div>
        </div>
      </div>
      <div className="open-mail-quote" style={{ display: openSearch ? "" : "none" }} onClick={() => setOpenSearch(false)}></div>
      <div className="product-banner">
        <div className="product-bredcrumb">
          {/* <div>{`Home >`}&nbsp;&nbsp;</div> */}
          <div className="pbredcrumb-menu" onClick={() => {
            setStep(["Products", "All Products"]);
            navigate('/products/All-Products')
            setcategorySelected(sidebarItems[0]?.category[0])
          }}>{`${step[0]} >`}&nbsp;&nbsp;</div>
          <div className="pbredcrumb-menu" onClick={() => {
            setStep(["Products", step[1]]);
            navigate(`/products/${removeSpecialCharacters(step[1]).split(" ").join("-")}`)
            setcollectionSelected("")
          }}>{`${step[1]} >`}&nbsp;&nbsp;</div>
          <div className="pbredcrumb-menu" onClick={() => {
            setStep(["Products", step[1], step[2]]);
            navigate(`/products/${removeSpecialCharacters(step[1]).split(" ").join("-")}/${removeSpecialCharacters(step[2]).split(" ").join("-")}`)
            setitemSelected("")
          }} style={{ display: step.length > 2 ? "" : "none" }}>{step.length > 2 ? `${step[2]} >` : ``}&nbsp;&nbsp;</div>
          <div className="pbredcrumb-menu" style={{ display: step.length > 3 ? "" : "none" }}>{step.length > 3 ? `${step[3]} >` : ``}&nbsp;&nbsp;</div>
        </div>
        <div className="product-text-block">
          <div className={"product-search-bar " + (globalSearch.length > 0 ? "search-result" : "")}>
            <div className="banner-data">
              <img src={SearchIcon} alt="Search Icon" className="search-icon" />
              <input type="search" alt="search" placeholder="Search Item . . . ." className="search-banner" value={globalSearch} onChange={(e) => setGlobalSearch(e.target.value)} />
              <p className="search-textbtn">Search</p>
            </div>
            <div className="product-display-data" style={{ display: globalSearch.length > 0 ? "" : "none" }}>
              <div style={{ display: globalSearch.length > 0 ? "" : "none" }}>
                {allProducts.filter((item) => item.name.toLowerCase().includes(globalSearch.toLowerCase())).map((item, index) => {
                  return <div key={index} className="selected-home-prod" onClick={() => openBox(item)}>
                    {item.name}<span style={{ display: !item.unit_type ? "none" : "" }}>-</span>&nbsp;{item.unit_type ? item.unit_type : ""}
                  </div>
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div>
        {
          step.length === 2 ?
            <>
              {navigate(`/products/${step[0]}`)}
              <Categories />
            </>
            : step.length === 3 ?
              <>
                {navigate(`/products/${step[0]}/${step[1]}`)}
                <Collections />
              </>
              : step.length === 4 ?
                <>
                  {navigate(`/products/${step[0]}/${step[1]}/${step[2]}`)}
                  <EachProduct />
                </> : ""

        }
      </div> */}
    </div>
  );
};

export default ProductPage;

