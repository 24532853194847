import React from "react";
import { useNavigate } from "react-router-dom";
import "./footer.scss";
import facebookIco from "../../assets/images/Footer/facebookIco.svg";
import linkedIco from "../../assets/images/Footer/linkedIco.svg";
import instaIco from "../../assets/images/Footer/instaIco.svg";
import whatsappIco from "../../assets/images/Footer/whatsappIco.svg";
import buildingIco from "../../assets/images/Footer/buildingIco.svg";
import callIco from "../../assets/images/Footer/callico.svg";
import mailIco from "../../assets/images/Footer/mailico.svg";
import a2zmainlogo from "../../assets/images/a2zmainlogo.svg";

const Footer = () => {
  let navigate = useNavigate();
  const socialIcons = [
    {
      name: "facebook",
      icon: facebookIco,
      link: "https://www.facebook.com/atozpartyrentals/"
    },
    // {
    //   name: "linkedIn",
    //   icon: linkedIco,
    // },
    {
      name: "instagram",
      icon: instaIco,
      link: "https://www.instagram.com/atozpartyrentals/"
    },
    // {
    //   name: "whatsapp",
    //   icon: whatsappIco,
    // },
  ];
  return (
    <>
      <div
        className="footer_container"
        style={{
          display:
            window.location.pathname === "/home" ||
              window.location.pathname === "/contact" ||
              window.location.pathname === "/reviews"
              ? ""
              : "none",
        }}
      >
        <div className="social-icons">
          {socialIcons.map((item, index) => {
            return (
              <div className="social-icon" key={index} onClick={() => window.open(item.link)}>
                <img src={item.icon} alt={item.name} className="footer-icons" />
              </div>
            );
          })}
        </div>
        <div className="contact-data">
          <p className="contact-title">Get In Touch</p>
          <div className="contact-info">
            <div className="contact-info-item">
              <div>
                <img src={buildingIco} alt="building" className="contact-ico" />
              </div>
              <div>
                <p className="contact-head">Meet US <span style={{ fontWeight: 600 }}>(BY APPOINTMENT)</span></p>
                <p className="contact-detail">
                  126 Sinnott Rd, Unit 3
                  <br />
                  Toronto, ON M1L 4N2
                </p>
              </div>
            </div>
            <div className="contact-info-item">
              <div>
                <img src={callIco} alt="building" className="contact-ico" />
              </div>
              <div>
                <p className="contact-head">Call Us</p>
                <p className="contact-detail">B : 416 - 755 - 8181</p>
                {/* <p className="contact-detail">C : 647 - 824 - 3545</p> */}
              </div>
            </div>
            <div className="contact-info-item">
              <div>
                <img src={mailIco} alt="building" className="contact-ico" />
              </div>
              <div>
                <p className="contact-head">Email US</p>
                <p className="contact-detail">info@atozpartyrentals.ca</p>
              </div>
            </div>
            <div className="contact-info-item">
              <div>
                <img src={mailIco} alt="building" className="contact-ico" />
              </div>
              <div>
                <p className="contact-head">Hours of Operations:</p>
                <p className="contact-detail">Mon - Sat : 9AM to 6pm</p>
                <p className="contact-detail">Sun : 10am to 2pm</p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <iframe title = "atoz" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11532.82328361557!2d-79.2802617!3d43.727051!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4ce0d7402ae5f%3A0x7fc435b5e97fd8b0!2sA%20to%20Z%20Party%20Rentals!5e0!3m2!1sen!2sin!4v1705895348472!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className="iframe-size"></iframe>
        </div>
      </div>
      <div className="copyright">
        <img src={a2zmainlogo} alt="a2zmainlogo" className="a2zmainlogo" />
        <p className="copyright-text">
          © COPYRIGHT 2023 A to Z Party Rentals Inc. | <span className="privacy-btn" onClick={() => window.open("/privacypolicy")}>Privacy</span>
        </p>
      </div>
    </>
  );
};

export default Footer;
