import React, { useEffect, useContext, useState } from "react";
import { MainContext } from "../../components/Context/context";
import "./contactpage.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Contact } from "../../services/getAPIs";

const ContactPage = () => {
  const { setnav, setGlobalSearch } = useContext(MainContext);
  useEffect(() => {
    setnav("Contact");
    setGlobalSearch("")
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const [isChecked, setIsChecked] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("")
  const [company, setCompany] = useState("")
  const [eventTime, setEventTime] = useState("")
  const [promoCode, setPromoCode] = useState("")
  const [message, setMessage] = useState("")

  const handleFormSubmit = async (e) => {
    const nameRegex = /^[A-Za-z\s]+$/;
    const phoneRegex = /^[0-9]+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    console.log(phoneRegex.test(phone), phone.length)
    if (!nameRegex.test(name)) {
      toast.error("Please enter valid name")
      return
    }
    if (!phoneRegex.test(phone)) {
      toast.error("Please enter valid phone number")
      return
    }
    if (phone.length !== 10) {
      toast.error("Phone number should be of 10 digits")
      return
    }
    if (!emailRegex.test(email)) {
      toast.error("Please enter valid email")
      return
    }
    if (!name) {
      toast.error("Please enter your name")
      return
    }
    if (!phone) {
      toast.error("Please enter your phone number")
      return
    }
    if (!email) {
      toast.error("Please enter your email")
      return
    }
    e.preventDefault();
    let data = {
      request_Type: "contactUs",
      name,
      phone,
      company_name: company,
      email,
      message,
      subscription: isChecked
    }

    Contact(data).then((res) => {
      if (res.data.status) {
        toast.success("Your request has been submitted successfully")
        setName("")
        setPhone("")
        setCompany("")
        setEmail("")
        setMessage("")
        setIsChecked(false)
      } else {
        toast.error("Something went wrong")
      }
    })
      .catch((err) => {
        console.log(err, "err")
      })
  }

  return (
    <div className="contact_container">
      <div className="wish-bg">
        <p className="wish-title">Contact Us</p>
      </div>
      <div className="information-form">
        <div className="information-title margin-contact">Request Information</div>
        <div className="information-desc">If you have any questions, please send us amessage and we will do our best to get backto you withing 24 hours.</div>
        <div className="wish-contact-form" style={{ color: "#a2483d", fontWeight: 700 }}>* Fields are mandatory</div>
        <div className="wish-contact-form">
          <input type="text" placeholder="Name*" className="wish-input" value={name} onChange={(e) => setName(e.target.value)} />
          <input type="number" placeholder="Phone Number*" className="wish-input left-marg-wish" value={phone}
            onChange={(e) => setPhone(e.target.value)} />
        </div>
        <div className="wish-contact-form">
          <input type="text" placeholder="Email*" className="wish-input"
            value={email} onChange={(e) => setEmail(e.target.value)} />
          <input type="text" placeholder="Company Name(optional)" className="wish-input  left-marg-wish"
            value={company} onChange={(e) => setCompany(e.target.value)} />
        </div>
        <div className="wish-contact-form">
          <textarea placeholder="Message(optional)" rows="8" cols="85" className="wish-textarea" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
        </div>
        <br />
        <div className="wish-contact-form subscribe-btn">
          <label class="switch">
            <input type="checkbox" checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
            <span class="slider round"></span>
          </label>
          <p className="subs-newsbtn">&nbsp;Subscribe to our newsletter to get updates on latest offers and seasonal items recommendations</p>
        </div>
        <br />
        {/* <div className="information-form">
          <p className="subs-newsbtn">On, subscribing you are accepting to our privacy policy</p>
          <p className="subs-newsbtn clickpolicybtn" onClick={() => window.open('/privacypolicy', '_blank')}>Click to read our privacy policy</p>
          </div> */}
        <div className="wish-form-submit" onClick={handleFormSubmit}>
          <p className="wish-form-title" >Submit</p>
        </div>
      </div>
      <div className="hr2"></div>
      <ToastContainer />
    </div>
  );
};

export default ContactPage;
