import React from "react";
import "./terms.scss";

const Terms = () => {
    return (
        <div className="terms-disp">
            {Array(10).fill(0).map((item, i) => {
                return <>
                    <br />
                    <p className="terms-head">Clause 1</p>
                    <br />
                    <p className="terms-desc">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra condimentum eget purus in. Consectetur eget id morbi amet amet, in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse aenean leo pharetra in sit semper et. Amet quam placerat sem.</p>
                    <br />
                    <hr />
                </>
            })}

        </div>
    );
}

export default Terms;