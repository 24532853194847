import React, { useEffect, useContext, useState, useRef } from "react";
import { MainContext } from "../../components/Context/context";
import { useNavigate } from "react-router-dom";
import footer from "../../components/footer/footer";
import "./homepage.scss";
// import MainBanner from "../../assets/images/Home/MainBanner.svg"
import SearchIcon from "../../assets/images/Home/searchIcon.svg"
import valueImg1 from "../../assets/images/Home/valueImg1.svg"
import valueImg2 from "../../assets/images/Home/valueImg2.svg"
import valueImg3 from "../../assets/images/Home/valueImg3.svg"
import valueImg4 from "../../assets/images/Home/valueImg4.svg"
import newImg1 from "../../assets/images/Home/newImg1.svg"
import newImg3 from "../../assets/images/Home/newImg3.svg"
import newImg4 from "../../assets/images/Home/newImg4.svg"
import newViewAll from "../../assets/images/Home/newViewAll.svg"
import cartIcon from "../../assets/images/Home/whiteWishlist.svg"
import zoomIcon from "../../assets/images/Home/zoomIcon.svg"
import viewAll1 from "../../assets/images/Home/viewAll.svg"
import plusIcon from "../../assets/images/Home/plusIcon.svg"
import minusIcon from "../../assets/images/Home/minusIcon.svg"
import bgimg1 from "../../assets/images/Home/bgimg1.svg"
import bgimg2 from "../../assets/images/Home/bgimg2.svg"
import bgimg3 from "../../assets/images/Home/bgimg3.svg"
import testimonialIcon from "../../assets/images/Home/testimonialIcon.svg"
import leftSlideIcon from "../../assets/images/Home/leftSlideIcon.svg"
import rightSlideIcon from "../../assets/images/Home/rightSlideIcon.svg"
import greenTick from "../../assets/images/Home/redWishlist.svg"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { homeBanner, valuePackages, Reviews } from "../../services/getAPIs"
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { seasonalItems, sidebarData, searchData } from "../../services/getAPIs";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader



const HomePage = () => {
  let navigate = useNavigate();
  const scrollableDivRef = useRef(null);
  const scrollableDivRef1 = useRef(null);
  const { setnav, wishListData, setwishListData, theItemsData, setTheItemsData, categorySelected, setcategorySelected, seasonalItemsData, setSeasonalItemsData, collectionSelected, setcollectionSelected, setitemSelected, step, setStep,
    globalSearch, setGlobalSearch, searchBox, allProducts, sidebarItems,
    openSearch, setOpenSearch, removeSpecialCharacters
  } = useContext(MainContext);
  const [theImageData, setTheImageData] = useState(cartIcon)
  const [addedItem, setAddedItem] = useState(false);
  const [homeImg, setHomeImg] = useState([])
  const [valuePackagesData, setValuePackagesData] = useState([])
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);
  const [reviewsData, setReviewsData] = useState([])
  const [direction, setDirection] = useState("current");
  const [quantities, setQuantities] = useState({});
  // keep track of the direction of the transition
  // const [openSearch, setOpenSearch] = useState(false)
  // const [seasonalItemsData, setSeasonalItemsData] = useState([])
  useEffect(() => {
    setnav("Home");
    setGlobalSearch("")
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

  }, [])

  const smoothScroll = (element, direction, amount, duration) => {
    const start = element.scrollLeft;
    console.log("scrollLeft" , start)
    const target = direction === 'right' ? start + amount : start - amount;
    const startTime = performance.now();

    const scrollStep = timestamp => {
      const currentTime = timestamp || performance.now();
      const progress = Math.min((currentTime - startTime) / duration, 1);

      element.scrollLeft = start + (target - start) * progress;

      if (progress < 1) {
        requestAnimationFrame(scrollStep);
      }
    };

    requestAnimationFrame(scrollStep);
  };


  const handleScroll = (direction) => {
    console.log("catescroll", direction)
    if (scrollableDivRef.current) {
      const scrollAmount = 200; // Adjust this value as needed
      const scrollDuration = 500; // Adjust this value as needed

      smoothScroll(scrollableDivRef.current, direction, scrollAmount, scrollDuration);
    }


  };
  const handleScroll1 = (direction) => {
    console.log("productScroll")
    if (scrollableDivRef1.current) {
      const scrollAmount = 200; // Adjust this value as needed
      const scrollDuration = 500; // Adjust this value as needed

      smoothScroll(scrollableDivRef1.current, direction, scrollAmount, scrollDuration);
    }
  }







  useEffect(() => {
    homeBanner().then((res) => {
      if (res.data.data.status) {
        setHomeImg(res.data.data.data)
        console.log(res.data.data.data, "home-banner-data")
      } else {
        toast.error(res.data.data.message)
      }
    }).catch((err) => {
      toast.error(err.message)
    })
    valuePackages().then((res) => {
      if (res.data.data.status) {
        // console.log(res.data.data.data, "value-packages-data")
        setValuePackagesData(res.data.data.data)
      } else {
        toast.error(res.data.data.message)
      }
    }).catch((err) => {
      toast.error(err.message)
    })
  }, [])

  useEffect(() => {
    seasonalItems().then((res) => {
      if (res.data.data.status) {
        // if item is in wish list set itemCount to count in wish list else set to 0
        res.data.data.data.map((item) => {
          if (wishListData.some((wishListItem) => wishListItem.product_id === item.product_id)) {
            item.itemCount = wishListData.find((wishListItem) => wishListItem.product_id === item.product_id).itemCount
          } else {
            item.itemCount = 0
          }
        })
        setSeasonalItemsData(res.data.data.data)

      } else {
        toast.error(res.data.data.message)
      }
    }).catch((err) => {
      toast.error(err.message)
    })
  }, [])


  const handleQuantityChange = (productId, quantity, item) => {
    console.log(productId, quantity, item.product_id)


    console.log(productId, "theID")

    //set the quantity of the item in the cart
    // setQuantities(prevQuantities => ({
    //   ...prevQuantities,
    //   [productId]: quantity
    // }));
    setQuantities({ [productId]: quantity })

    seasonalItemsData.forEach((item) => {
      if (item.product_id === productId) {
        item.itemCount = quantity
      }
    })
    console.log(seasonalItemsData, "seasonalItemsData")
    setSeasonalItemsData(seasonalItemsData)

    console.log(quantities[item.product_id])

    //add item to wishlist
    if (quantity > 0) {
      // if item is not in wishlist add it
      if (!wishListData.some((wishListItem) => wishListItem.product_id === productId)) {
        wishListData.push(item)
        setwishListData(wishListData)
      } else {
        // if item is in wishlist update the quantity
        wishListData.forEach((wishListItem) => {
          if (wishListItem.product_id === productId) {
            wishListItem.itemCount = quantity
          }
        })
        setwishListData(wishListData)
      }
    } else if (quantity === 0 || isNaN(quantity)) {
      setwishListData(wishListData.filter((obj) => obj.product_id !== item.product_id))
    }


  };

  useEffect(() => {
    Reviews().then((res) => {
      if (res.data.data.status) {
        setReviewsData(res.data.data.data)
      }
    }).catch((err) => {
      console.log(err)
    })
  }, [])


  const BgImgData = [
    {
      image: bgimg1,
      text: "Access More",
      // subtext: "Pursue passions. Get things done."
    },
    {
      image: bgimg2,
      text: "Save money",
      // subtext: "Rent for a fraction of the cost."
    },
    {
      image: bgimg3,
      text: "Celebrate",
      // subtext: "Live lighter. Reduce waste."
    }
  ]




  const addItem = (item) => {
    // let temp = [...seasonalItemsData];
    // let index = temp.indexOf(item);
    // temp[index].itemCount = temp[index].itemCount + 1;
    // setSeasonalItemsData(temp);
    // if (!wishListData.includes(item)) {
    //   setwishListData([...wishListData, item])
    // }

    // console.log(item, "add-item")
    if (item.itemCount === 0 || item.itemCount === undefined || isNaN(item.itemCount)) {
      let temp = [...seasonalItemsData];
      let index = temp.indexOf(item);
      temp[index].itemCount = 1;
      setSeasonalItemsData(temp);
      setwishListData([...wishListData, item])
    } else {
      setwishListData(wishListData.map((obj) => obj.product_id === item.product_id ? { ...obj, itemCount: obj.itemCount + 1 } : obj))

      let temp = [...seasonalItemsData];
      let index = temp.indexOf(item);
      temp[index].itemCount = temp[index].itemCount + 1;
      setSeasonalItemsData(temp);
    }
  }

  const removeItem = (item) => {
    if (item.itemCount === 0) {
      // toast.error("Item count cannot be less than 0")
      return
    }
    let temp = [...seasonalItemsData];
    let index = temp.indexOf(item);
    temp[index].itemCount = temp[index].itemCount - 1;
    setSeasonalItemsData(temp);
    setwishListData(wishListData.map((obj) => obj.product_id === item.product_id ? { ...obj, itemCount: obj.itemCount - 1 } : obj))
    if (item.itemCount === 0) {
      setwishListData(wishListData.filter((obj) => obj.product_id !== item.product_id))
    }
  }


  const addtoWishlist = (item) => {

    //add itemCount to item
    if (wishListData.find((wishListItem) => wishListItem.product_id === item.product_id)) {
      toast.success("Item added to wishlist");
      return
    } else {
      item.itemCount = 1;
      setwishListData([...wishListData, item])
      toast.success("Item added to wishlist");
    }
  }

  // const ImageDisp = (item) => {
  //   if (wishListData.includes(item)) {
  //     setTheImageData(greenTick)
  //   } else {
  //     setTheImageData(cartIcon)
  //   }
  // }

  const collectionSelect = async (item) => {
    setcollectionSelected(item.collections[0])

    let getSidebarData = await sidebarData();
    getSidebarData.data.data.data.map((obj) => {
      if (obj.category[0].category_id === item.collections[0].category[0].category_id) {
        setcategorySelected(obj.category[0])
      }
    })

    navigate(`/products/${removeSpecialCharacters(item.collections[0].category[0].name).split(" ").join("-")}/${removeSpecialCharacters(item.collections[0].name).split(" ").join("-")}`)
    setStep(["Products", item.collections[0].category[0].name, item.collections[0].name])
  }

  const openBox = async (item) => {

    let searchResults = await searchData();
    // console.log(item, "clicked")
    // console.log(JSON.stringify(searchResults.data.data.data), "searchResults")
    searchResults.data.data.data.forEach(element => {
      element.category[0].collections.forEach(element2 => {
        element2[0].products.forEach(element3 => {
          if (element3.product_id === item.product_id) {
            // console.log(element3, "element3")
            setitemSelected(element3);
            setcollectionSelected(element2[0]);
            setcategorySelected(element.category[0]);
            setStep(["Products", element.category[0].name, element2[0].name, element3.name]);
            navigate(`/products/${removeSpecialCharacters(element.category[0].name).split(" ").join("-")}/${removeSpecialCharacters(element2[0].name).split(" ").join("-")}/${removeSpecialCharacters(element3.name).split(" ").join("-")}`)
            setOpenSearch(false);
            setGlobalSearch("");
          }
        });
      });
    });
    window.scrollTo({
      top: 200,
      behavior: "smooth"
    });
  }


  const handleNextReview = () => {
    setDirection("next");
    setCurrentReviewIndex((prevIndex) =>
      prevIndex === reviewsData.length - 1 ? 0 : prevIndex + 1
    );
    // setDirection("current");
    setTimeout(() => {
      setDirection("current");
    }, 500);
  };

  const handlePrevReview = () => {
    setDirection("prev");
    setCurrentReviewIndex((prevIndex) =>
      prevIndex === 0 ? reviewsData.length - 1 : prevIndex - 1
    );
    setTimeout(() => {
      setDirection("current");
    }, 500);
    // setDirection("current");
  };

  const handleTransitionEnd = () => {
    // console.log("transition ended");
    if (direction === "next") {
      setCurrentReviewIndex((prevIndex) =>
        prevIndex === reviewsData.length - 1 ? 0 : prevIndex + 1
      );
    } else if (direction === "prev") {
      setCurrentReviewIndex((prevIndex) =>
        prevIndex === 0 ? reviewsData.length - 1 : prevIndex - 1
      );
    }
    setDirection("current");
  };

  const openreviews = () => {
    navigate('/reviews')
    //scroll to top
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  const viewAllBtn = () => {
    navigate("/products/All-Products")
    setStep(["Products", "All Products"])
    setcategorySelected(sidebarItems[0]?.category[0])
  }



  return (
    <>
      <div className="home_container">
        <div className="home-search-disp">
          <div className="home-search" style={{ display: openSearch ? "" : "none" }}>
            <div className="banner-search-bar">
              <img src={SearchIcon} alt="Search Icon" className="search-icon" style={{ top: "23px" }} />
              <input type="search" alt="search" placeholder="Search Item . . . ." className="search-banner" value={globalSearch} onChange={(e) => setGlobalSearch(e.target.value)} style={{ paddingLeft: "30px" }} />
              <p className="search-textbtn">Search</p>
            </div>
            <div style={{ display: globalSearch.length > 0 ? "" : "none" }}>
              {allProducts.filter((item) => item.name.toLowerCase().includes(globalSearch.toLowerCase())).map((item, index) => {
                return <div key={index} className="selected-home-prod" onClick={() => openBox(item)}>
                  {item.name}
                </div>
              })}
            </div>
          </div>
        </div>
        <div className="open-mail-quote" style={{ display: openSearch ? "" : "none" }} onClick={() => setOpenSearch(false)}></div>
        <div className="home-banner">
          {/* <img src={homeImg[0]} alt="Main Banner" className="img-banner" /> */}
          <Carousel
            infiniteLoop={true}
            autoPlay={true}
          >
            {homeImg?.map((item, index) => {
              return <div className="the-main-carousel">
                <img src={item} alt="carousel-2" className="the-carousel-height" />
              </div>
            })}
          </Carousel>
          <div className="center-text-banner" style={{ marginTop: globalSearch.length > 0 ? "130px" : "0px" }}>
            <p className="banner-text">Quality&nbsp;Rentals&nbsp;For&nbsp;all&nbsp;occasions</p>
            <div className="banner-text-block">
              <div className={"banner-search-bar " + (globalSearch.length > 0 ? "search-result" : "")}>
                <div className="banner-data">
                  <img src={SearchIcon} alt="Search Icon" className="search-icon" />
                  <input type="search" alt="search" placeholder="Search Item . . . ." className="search-banner" value={globalSearch} onChange={(e) => setGlobalSearch(e.target.value)} />
                  <p className="search-textbtn">Search</p>
                </div>
                <div className="search-display-data" style={{ display: globalSearch.length > 0 ? "" : "none" }}>
                  <div style={{ display: globalSearch.length > 0 ? "" : "none" }}>
                    {allProducts.filter((item) => item.name.toLowerCase().includes(globalSearch.toLowerCase())).map((item, index) => {
                      return <div key={index} className="selected-home-prod" onClick={() => openBox(item)}>
                        {item.name}<span style={{ display: !item.unit_type ? "none" : "" }}>-</span>&nbsp;{item.unit_type ? item.unit_type : ""}
                      </div>
                    })}
                  </div>
                </div>
              </div>



            </div>
            {/* <Carousel
            infiniteLoop={true}
            autoPlay={true}
            dynamicHeight={400}>
            <div className="the-main-carousel">
              <img src={homeImg[0]} alt="carousel-2" />
            </div>
            <div className="the-main-carousel">
              <img src={homeImg[1]} alt="carousel-3" />
            </div>
          </Carousel> */}

          </div>
        </div>
        <div className="full-page-container">
          <div className="value-banner">
            <div className="value-banner-title">
              <p className="value-banner-text">Your favorite Categories are <span className="value-highlight">Right Here!</span></p>
              <div className="hr"></div>
            </div>
            <div className="value-favs">
              <button className="scroll-button scroll-left" onClick={() => handleScroll('left')}>
                &lt;
              </button>
              <div className="value-banner-block" ref={scrollableDivRef}>

                {valuePackagesData.length > 0 ? (
                  valuePackagesData.map((item, index) => {
                    return <>
                      <div className="value-categories" onClick={() => collectionSelect(item)}>
                        <p className="value-title">{item?.collections[0]?.name}</p>
                        <img src={item?.collections[0]?.images[0]} alt="Value" className="value-img" />
                        <p className="value-btn">View Collection</p>
                      </div>
                    </>
                  })
                ) : (
                  Array(4).fill().map((item, index) => {
                    return (
                      <div className="value-categories">
                        <Skeleton height={350} width={214} />
                      </div>
                    )
                  })
                )}
                {/* <button className="scroll-button" onClick={() => handleScroll('right')}>
                  &gt;
                </button> */}
                {/* <div className="value-categories cat-style" >
                  <p className="value-title"></p>
                  <img src={newImg1} alt="Value" className="value-img" />
                  <p className="value-btn">View Collection</p>
                </div> */}

                {/* <div className="view-all-img" onClick={viewAllBtn}>
                  <p className="all-title">VIEW ALL</p>
                  <p className="all-main-text">Furniture & Appliances for Rent</p>
                  <img src={viewAll1} alt="View All" className="value-viewall-img" />
                </div> */}
              </div>
              <div>
                {/* <button onClick={() => handleScroll('left')}>Left</button> */}
                <button className="scroll-button scroll-right" onClick={() => handleScroll('right')}>
                  &gt;
                </button>

              </div>
            </div>
            <div className="view-btn" onClick={viewAllBtn}>
              VIEW ALL
            </div>
            {/* <div className="view-all-img" onClick={viewAllBtn}>
              <p className="all-title">VIEW ALL</p>
              <p className="all-main-text">Furniture & Appliances for Rent</p>
              <img src={viewAll1} alt="View All" className="value-viewall-img" />
            </div> */}

          </div>
          <div className="new-items">
            <div className="value-banner-title">
              <p className="value-banner-text"> <span className="value-highlight">Popular Items</span> For this Season!</p>
              <div className="hr1"></div>
              <div className="value-favs">
                <button className="scroll-button scroll-left" onClick={() => handleScroll1('left')}>
                  &lt;
                </button>
                <div className="value-banner-block" ref={scrollableDivRef1}>
                  {seasonalItemsData.map((item, index) => {
                    if (isNaN(item.itemCount)) {
                      item.itemCount = 0
                    }
                    return <div className="new-categories" key={index} onClick={() => openBox(item)}>
                      <div className="newbox-imgs">
                        <img src={wishListData.find((wishListItem) => wishListItem.product_id === item.product_id) && item.itemCount !== 0 ? greenTick : cartIcon} alt="Cart" className="cart-icon" onClick={(e) => {
                          addtoWishlist(item)
                          e.stopPropagation()
                        }} />
                        <img src={item?.images[0]} alt="New" className="new-img" />
                      </div>
                      <div className="item-details">
                        <p className="new-title">{item.name}&nbsp;<span style={{ display: !item.unit_type ? "none" : "" }}>-</span>&nbsp;{item.unit_type ? item.unit_type : ""}</p>
                        <p className="new-price">${item.price ? parseFloat(item.price).toFixed(2) : "--"}</p>
                      </div>
                      <div className="item-details-2">
                        <p className="add-btn-text">Quantity</p>
                        <div className="add-item-border">
                          <img src={minusIcon} alt="Minus" className="minus-icon" onClick={(e) => {
                            removeItem(item)
                            e.stopPropagation()
                          }} />
                          {/* <p className="item-number">{item.itemCount}</p> */}
                          <input type="number" min="0" className="item-number" value={item.itemCount}
                            onChange={(e) => handleQuantityChange(item.product_id, parseInt(e.target.value), item, e.stopPropagation())} onClick={(e) => e.stopPropagation()}

                          />
                          <img src={plusIcon} alt="Plus" className="plus-icon" onClick={(e) => {
                            addItem(item)
                            e.stopPropagation()
                          }} />
                        </div>
                      </div>
                      <p class="home-item-btn" onClick={(e) => {
                        addtoWishlist(item)
                        e.stopPropagation()
                      }} >Add to Wishlist</p>
                    </div>
                  })}
                  {/* <div className="new-categories cat-style" >
                    <div className="newbox-imgs">
                      <img src={cartIcon} alt="Cart" className="cart-icon" />
                      <img src={cartIcon} alt="New" className="new-img" />
                    </div>
                    <div className="item-details">
                      <p className="new-title"></p>
                      <p className="new-price">$</p>
                    </div>
                    <div className="item-details-2">
                      <p className="add-btn-text">Quantity</p>
                      <div className="add-item-border">
                        <img src={minusIcon} alt="Minus" className="minus-icon" />
                        <p className="item-number"></p>
                        <img src={plusIcon} alt="Plus" className="plus-icon" />
                      </div>
                    </div>
                    <p class="home-item-btn" >Add to Wishlist</p>
                  </div> */}
                  {/* <div className="view-all-img" onClick={viewAllBtn}>
                    <p className="all-title">VIEW ALL</p>
                    <p className="all-main-text">Furniture & Appliances for Rent</p>
                    <img src={newViewAll} alt="View All" className="value-viewall-img" />
                  </div> */}
                </div>
                <button className="scroll-button scroll-right" onClick={() => handleScroll1('right')}>
                  &gt;
                </button>
              </div>
              <div className="view-btn" onClick={viewAllBtn}>
                VIEW ALL
              </div>
              <br />
            </div>
          </div>
        </div>
        <div className="background-bar">
          {
            BgImgData.map((item, index) => {
              return <div className="background-bar-block">
                <img src={item.image} alt="Background" className="bg-img" />
                <p className="bg-text-title">{item.text}</p>
                <p className="bg-text-subtitle">{item.subtext}</p>
              </div>
            })
          }
        </div>

        <div className="small-brief">
          <p className="brief-title"><span className="brief-color">A to Z Party Rentals</span> is recognized as a leading company in the event rental industry.</p>
          <div className="hr"></div>
          <p className="brief-text">With its innovative designs and commitment to exceptional customer relationships, A to Z Party Rentals has established itself as a true leader in the event rental industry. A to Z's reputation for reliability and expertise has been earned through years of serving major venues across GTA, giving clients the confidence that their rental items will be delivered as expected and their events will run smoothly. Whether you're in need of unique rental items or expert guidance, the A to Z team is dedicated to providing superior service that exceeds expectations.</p>
        </ div>
        <div className="testimonials">
          <p className="testimonials-title"><span className="brief-color">Happy Customers</span> of A to Z Party Rentals</p>
          <div className="hr-testimonial"></div>
          <div className="testimonials-block">
            <div className="testimonials-block-1">
              <p className="testimonals-text-title">We go the extra mile to ensure our customers have a great rental experience</p>
              <div className="testimonials-img">
                <img src={testimonialIcon} alt="Testimonials" className="testimonial-icon" />
              </div>
            </div>
          </div>
          <div className="testimonials-details">
            <div className="testimonial-icons">
              <img src={leftSlideIcon} alt="Testimonial" className="testimonials-move" onClick={handlePrevReview} />
            </div>
            <div className={`testimonials-data`}>
              <p className="testimonials-user-text">{reviewsData[currentReviewIndex]?.description}</p>
              <p className="testimonials-name">- -{reviewsData[currentReviewIndex]?.name}</p>
            </div>
            <div className="testimonial-icons">
              <img src={rightSlideIcon} alt="Testimonial" className="testimonials-move" onClick={handleNextReview} />
            </div>
          </div>
          <div className="reviews">
            <p className="reviews-text" onClick={openreviews}>View More Reviews</p>
          </div>
        </div>
        <ToastContainer style={{ top: '100px' }} />
      </div>
    </>
  );
};

export default HomePage;
