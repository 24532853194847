import React, { useContext, useState, useEffect } from "react";
import { MainContext } from "../Context/context";
import { productData } from "../../data";
import rightarrow from "../../assets/images/products/rightarrow.svg";
import downarrow from "../../assets/images/products/downarrow.svg";
import dot from "../../assets/images/products/dot.svg";
import { sidebarData } from "../../services/getAPIs";
import "./ProductSidebar.scss";
import { useNavigate } from "react-router-dom";

const ProductsSidebar = () => {
  const { setnav, categorySelected, setcategorySelected,
    collectionSelected, setcollectionSelected, step, setStep, sidebarItems, setSidebarItems, urlCategory, setUrlCategory, removeSpecialCharacters } = useContext(MainContext);

  // category: [
  //   {
  //     category_id: "QRjWgK4i6",
  //     name: "Beverage Equipment",
  //     collections: [
  //       [
  //         {
  //           collection_id: "fld-KmQ-R",
  //           name: "Hot Or Cold Beverage Dispensers",
  //           images: [
  //             "https://atoztesting.s3.amazonaws.com/f7c486e1acd4c4ba7ef03f6abf8d94f5"
  //           ]
  //         }
  //       ]
  //     ]
  //   }
  // ]



  console.log(categorySelected, "data")
  const navigate = useNavigate();
  return (
    <div className="sidebox-container">
      <div className="product-sidebar-container">
        {sidebarItems.length > 0 ?
          (sidebarItems.map((each, i) => {
            // if (urlCategory === each.category[0].name.split(" ").join("-")) {
            //   setcategorySelected(each.category[0]);
            //   setcollectionSelected({});
            //   setStep([step[0], each.category[0].name]);
            //   navigate(`/products/${each.category[0].name.split(" ").join("-")}`)
            // }
            return (
              <div key={i}>
                <div className="menu-title-box"
                  onClick={() => {
                    setcategorySelected(each.category[0]);
                    setcollectionSelected({});
                    setStep([step[0], each.category[0].name]);
                    navigate(`/products/${removeSpecialCharacters(each.category[0].name).split(" ").join("-")}`)
                    window.scrollTo({
                      top: 200,
                      behavior: "smooth"
                    });
                  }}
                >
                  <div className="menu-tile" style={{ color: categorySelected.category_id === each.category[0].category_id ? "#a2483d" : "" }}>{each.category[0].name}</div>
                  <div className="arrow-img">
                    <img src={categorySelected.category_id === each.category[0].category_id ? downarrow : rightarrow} alt="" />
                  </div>
                </div>
                <div style={{ display: categorySelected.category_id === each.category[0].category_id ? "" : "none" }}>
                  {each.category[0].collections.length > 0 && i !== 0 && i !== 1 ? (
                    // {each.category[0].collections.length > 0 ? (
                    <>
                      {each.category[0].collections.map((item, j) => (
                        <div key={j} className="sub-menu-box" style={{
                          color: collectionSelected.collection_id === item[0].collection_id ? "#a2483d" : "",
                          background: collectionSelected.collection_id === item[0].collection_id ? "rgba(0, 0, 0, 0.1)" : "", borderRadius: collectionSelected.collection_id === item[0].collection_id ? "10px" : ""
                        }} onClick={() => {
                          setcollectionSelected(item[0]);
                          navigate(`/products/${removeSpecialCharacters(each.category[0].name).split(" ").join("-")}/${removeSpecialCharacters(item[0].name).split(" ").join("-")}`)
                          step.length === 3 ?
                            setStep([step[0], step[1], item[0].name])
                            : setStep([...step, item[0].name]);
                          window.scrollTo({
                            top: 200,
                            behavior: "smooth"
                          });
                        }}>
                          <div className="dot-img">
                            <img src={dot} alt="" />
                          </div>
                          <div className="sub-menu-tile">{item[0].name}</div>
                        </div>
                      ))}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>)
          })) : (
            <div className="menu-title-box">
              <div className="menu-tile">No Data Found</div>
            </div>
          )
        }

      </div>
      {/* <div className="price-list-btn">
        Download
        <br />
        Price List
      </div> */}
    </div>
  );
};

export default ProductsSidebar;
